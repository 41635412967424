import React, {useState} from 'react';
import EmailContacts from './Contacts'
import ContactList from './ContactList'
import EmailCampaigns from "./EmailCampaigns";
import EmailTemplates from "./EmailTemplates";

const EmailDashboard = ({ setShowModal= {setShowModal}, setModalType={setModalType} }) => {
    const [contacts, setContacts] = useState(null);

    return (
        <div>
            <div className="flex w-full py-12">
                <div className="border-black border-2 w-[33%] h-[500px] rounded-md mx-4"><ContactList setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} /></div>
                <div className="border-black border-2 w-[33%] h-[500px] rounded-md mx-4"><EmailCampaigns setShowModal={setShowModal} setModalType={setModalType} /></div>
                <div className="border-black border-2 w-[33%] h-[500px] rounded-md mx-4"><EmailTemplates setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} /></div>
            </div>
            <div className="w-screen flex justify-center items-center">
                <EmailContacts setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} />
            </div>
        </div>
    )
}

export default EmailDashboard;
