import React, { useState } from "react";

const PaginatedTable = ({ data, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // calculate total pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // get current items to display
  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleClick = (pageNum) => () => setCurrentPage(pageNum);

  return (
    <div className="pl-[120px] pr-[120px] mt-16 ">
      <p>Materials Created From The AI Generator:</p>
      <table>
        <thead>
            <th>Date Created:</th>
            <th>Worksheets, Plans, Labs, Tests:</th>
        </thead>
        <tbody>
          {currentItems.map(item => (
            <tr key={item.id}>
              <td className="py-[2px] px-[6px] w-[110px] border border-black-200"><a href={`https://www.teachersaide.io/submitted-prompts/${item.promptToken}`} target="_blank" rel="noopener noreferrer" className="text-black-700 hover:text-indigo-700">{item.createdAt.slice(0,10)}</a></td>
              <td className="py-[10px] px-[14px] border border-black-200"><a href={`https://www.teachersaide.io/submitted-prompts/${item.promptToken}`} target="_blank" rel="noopener noreferrer" className="text-black-700 hover:text-indigo-700">{item.response.slice(0,180)+"..."}</a></td>
              {/*<td>{item.promptToken}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pl-20 mt-[10px]">
        {/* Pagination buttons */}
        {[...Array(totalPages)].map((_, index) => (
          <button 
                key={index} 
                onClick={handleClick(index + 1)} 
                style={{
                    backgroundColor: currentPage === index + 1 ? "blue" : "white", // change as desired
                    color: currentPage === index + 1 ? "white" : "black" // change as desired
                  }}
                className="px-2 h-[40px] w-[40px]">
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PaginatedTable;
