import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard";
import Footer from "../Footer";

function About() {

    return (
<div>
    <div className='flex flex-row w-full h-[1200px]'>
      <Dashboard />
      <div className="flex flex-col items-center w-screen">
        <h2 className="pt-12 text-center text-4xl font-bold tracking-tight text-gray-900">Empowering Educators with AI - Our Journey</h2>
        <br></br>
        <div className="px-24">
            <br></br>
            <br></br>
            <p>
                Welcome to TeachersAIde.io, an innovative teaching assistant designed by an ex-educators with a passion for empowering teachers. Our journey began with a simple yet profound vision: utilize the latest technologies to assist teachers in the classroom.
                <br></br>
                <br></br>
                <h2 className=" font-bold tracking-tight text-gray-900">Team</h2>
                Our dedicated team comprises of experienced educators and innovative technologists who deeply understand the daily challenges faced by teachers. With collective years of firsthand experience in the classroom, our team envisioned a tool capable of empowering teachers - leading to the creation of TeachersAIde.io
                <br></br>
                <br></br>
                <h2 className=" font-bold tracking-tight text-gray-900">Mission Statement</h2>
                Our mission is simple: to aid teachers by saving time, enhancing teaching abilities, and reducing stress. We believe that by supporting educators, we uplift the entire educational ecosystem.
                
                <br></br>
                <br></br>
                <h2 className=" font-bold tracking-tight text-gray-900">Impact and Testimonials</h2>
                Hear from educators who have transformed their teaching experience:

                <div className="pt-4">
                    <div>
                        <h3>Sarah Thompson, High School Math Teacher</h3>
                        <p className="pl-4">"Since incorporating TeachersAIde.io into my classroom, I've noticed a significant increase in my efficiency. The automated lesson generator saves hours each week, allowing me to focus more on individual student needs. It's a game-changer!"</p>
                    </div>

                    <div className="pt-4">
                        <h3>Mark Johnson, Middle School Science Teacher</h3>
                        <p className="pl-4">"Teaching can be overwhelming, but TeachersAIde.io has been a stress reliever. Its lab builder allows me to create new science labs in an instance. I feel more in control and less stressed."</p>
                    </div>

                    <div className="pt-4">
                        <h3>Emily Rodriguez, Elementary School Teacher</h3>
                        <p className="pl-4">"TeachersAIde.io has brought a new level of engagement to my classroom. The personalized learning plans have made my students more excited about learning. I've seen a noticeable improvement in their participation and enthusiasm."</p>
                    </div>

                    <div className="pt-4">
                        <h3>Alex Kim, ESL Teacher</h3>
                        <p className="pl-4">"What I love about TeachersAIde.io is not just its AI tools but also its professional development resources. It has helped me enhance my teaching techniques and stay updated with the latest educational trends."</p>
                    </div>

                    <div className="pt-4">
                        <h3>Linda Garcia, Special Education Teacher</h3>
                        <p className="pl-4">"The accessibility features of TeachersAIde.io are outstanding. It ensures that all students, regardless of their learning needs, can benefit equally. The support team is also incredibly responsive and helpful, making the experience even more rewarding."</p>
                    </div>
                </div>

                <br></br>
                <h2 className=" font-bold tracking-tight text-gray-900">Vision for the Future</h2>
                We are committed to continuous innovation, aiming to further empower teachers and reshape the future of education. Join us on this exciting journey!
            </p>
        </div>
      </div>
     </div>
     <Footer />
    </div>    
    )
}

export default About;