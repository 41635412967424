import React, {useEffect, useState} from 'react';
import {csrfFetch} from "../../store/csrf";
import { ReactComponent as Logo } from "../Assets/title.svg"

const RegisterAccount = () => {
    const urlParts = window.location.pathname.split("/");
    const [username, setUsername] = useState(urlParts[urlParts.length - 1])
    const [user, setUser] = useState(null);
    const [newUsername, setNewUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [usernameErrors, setUsernameErrors] = useState("");
    const [passwordErrors, setPasswordErrors] = useState("");
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState("");
    const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        async function fetchUser() {

            if (username) {
                const response = await csrfFetch(`/api/users/find-username`, {
                    method: "POST",
                    body: JSON.stringify({
                        username: username
                    }),
                });
                const data = await response.json();
                setUser(data.user)
                setNewUsername(data.user.email.split('@')[0])
            }
        }
        fetchUser();
    }, [username]);

    const validateUsername = (username) => {
        const usernameRegex = /^[a-zA-Z0-9]{3,15}$/;
        if (!usernameRegex.test(username)) {
            return 'Username must be alphanumeric and 3-15 characters long.';
        }
        return '';
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(password)) {
            return 'Password must be at least 8 characters long and contain an uppercase letter and one special character.';
        }
        return '';
    };

    const validateConfirmPassword = (confirmPassword) => {
        if (confirmPassword !== password) {
            return 'Passwords must match.';
        }
        return '';
    };

    const updateNewUsername = (e,updatedUsername) => {
        e.preventDefault();
        const usernameError = validateUsername(newUsername);
        setUsernameErrors(usernameError)
        setNewUsername(updatedUsername)
    }

    const updatePassword = (e, updatedPassword) => {
        e.preventDefault();
        const passwordError = validatePassword(updatedPassword);
        setPasswordErrors(passwordError)
        setPassword(updatedPassword)
    }

    const updateConfirmPassword = (e, updatedConfirmPassword) => {
        e.preventDefault();
        const confirmPasswordError = validateConfirmPassword(updatedConfirmPassword);
        setConfirmPasswordErrors(confirmPasswordError)
        console.log(confirmPasswordError)
        setConfirmPassword(updatedConfirmPassword)
    }

    const updateCredentials = async(e) => {
        e.preventDefault();
        if (confirmPasswordErrors.length !== 0 || passwordErrors.length !== 0  || usernameErrors.length !== 0) {
            return;
        }
        const response = await csrfFetch(`/api/users/register-user-new-sub`, {
            method: "POST",
            body: JSON.stringify({
                id: user.id,
                newUsername: newUsername,
                password: password
            }),
        });
        const data = await response;
        if (data.status === 200) {
            setFormDisabled(true)
            setShowConfirmPopUp(true);
        }
    }

    return (
        <div className="w-screen flex flex-col justify-center items-center">
            <Logo className="w-[250px] mt-12" />
            {showConfirmPopUp &&
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 w-screen md:w-[500px] flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-xl">
                        <div className="flex flex-col items-center p-10">
                            <p className="text-center font-owners-text font-bold text-2xl text-black tracking-[2px] animate-grow">Registration complete!</p>
                            <a href='https://www.teachersaide.io/' rel="noopener noreferrer"
                                    className="mt-8 bg-[#E1341E] w-[200px] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                                Redirect to Teachersaide.io
                            </a>
                        </div>
                    </div>
                </div>
            }
            <div className="mt-16">
            </div>
            <p>Register Your Account</p>
            {!formDisabled &&
            <form onSubmit={updateCredentials} className="w-[90%] md:w-[500px]">
                <label>Username</label>
                <input
                  type="text"
                  value={newUsername}
                  onChange={e=> updateNewUsername(e, e.target.value)}
                  required
                  className="h-10 border border-grey-100 px-4 w-full mb-4"
                />
                {usernameErrors.length > 0 && <p className="text-red-500">{usernameErrors}</p>}
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={e=> updatePassword(e, e.target.value)}
                    required
                    className="h-10 border border-grey-100 px-4 w-full mb-4"
                />
                {passwordErrors.length > 0 && <p className="text-red-500">{passwordErrors}</p>}
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => updateConfirmPassword(e, e.target.value)}
                    required
                    className="h-10 border border-grey-100 px-4 w-full mb-4"
                    placeholder="Confirm Password"
                />
                {confirmPasswordErrors.length > 0 && <p className="text-red-500">{confirmPasswordErrors}</p>}
                {confirmPasswordErrors.length == 0 && passwordErrors.length == 0  && usernameErrors.length == 0 ?
                    <button className="mx-2 mb-4 group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" type="submit">Send</button>:
                    <button disabled className="opacity-30 mx-2 mb-4 group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" type="submit">Send</button>
                }
            </form>
            }
        </div>
    )
}

export default RegisterAccount;
