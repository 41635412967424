import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import AddContact from "./AddContact";

const EmailContacts = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [showAddContactForm, setShowAddContactForm] = useState(false);
    const [editIndex, setEditIndex] = useState(null); // State to track which row is being edited
    const [editContact, setEditContact] = useState({}); // State to store edited contact data
    const [selectedContacts, setSelectedContacts] = useState(null);
    const [alphaPagination, setAlphaPagination] = useState(['all', 'a', 'b' ,'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']);
    const [selectedAlphaPagination, setSelectedAlphaPagination] = useState('all');
    const [alphaObject, setAlphaObject] = useState(null);

    useEffect(() => {
        const fetchContacts = async () => {
            const response = await csrfFetch('/api/emails/fetch-contacts');
            const data = await response.json();
            setContacts(data.response.contacts);
            setAlphaObject(data.response.alphabetObject)
            setSelectedContacts(data.response.alphabetObject["all"]);
        }
        if (!contacts) {
            fetchContacts();
        };
    })

    const handleEditClick = (index, contact) => {
        setEditIndex(index); // Set the index of the row being edited
        setEditContact(contact); // Store the current contact data in the state
    };

    const handleChange = (e) => {
        setEditContact({
            ...editContact,
            [e.target.name]: e.target.value,
        });
    };

    const handleSaveClick = async() => {
        let updatedContacts = [...contacts];
        updatedContacts[editIndex] = editContact;
        setContacts(updatedContacts);
        // Save the changes and reset the editing state
        // This is where you would typically send the updated contact data to the server
        const response = await csrfFetch('/api/emails/update-contact', {
            method: 'POST',
            body: JSON.stringify({
                editContact
            }),
        })
        const data = await response.json();
        setEditIndex(null); // Exit editing mode
    };

    const addNewContact = (e) => {
        e.preventDefault();
        setModalType(<AddContact setShowModal={setShowModal} setModalType={setModalType} />)
        setShowModal(true);
    }


    const archiveContact = async(index, contact) => {
        const ta_contact_id = contact.ta_contact_id;
        // Save the changes and reset the editing state
        // This is where you would typically send the updated contact data to the server
        const response = await csrfFetch('/api/emails/archive-contact', {
            method: 'POST',
            body: JSON.stringify({
                ta_contact_id
            }),
        })
        const data = await response.json();
        setEditIndex(null); // Exit editing mode
    }


    const setAlphaPage = (letter) => {
        setSelectedAlphaPagination(letter);
        setSelectedContacts(alphaObject[letter])
    }

    return (
        <div className="w-11/12 border-[.5px] border-black rounded-md mb-32 pb-8">
            <div className="px-12 font-owners-text mt-8">
                <p>Contacts</p>
                <div className="flex justify-between">
                    <p>A list of all marketing contacts</p>
                    <button className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={(e) => addNewContact(e)}>Add Contact</button>
                </div>
            </div>

            <div className="pl-12 mb-6 flex items-center">
                <p className="font-owners-text text-sm mt-[1px] mr-1">Filter By Last Name: </p>
                {alphaPagination.map((letter, index) => (
                    <a className="text-indigo-600 px-[2.5px] cursor-pointer" onClick={() => setAlphaPage(letter)}>{letter}</a>
                ))
                }
            </div>
            <div className="flex flex-col justify-center items-center font-owners-text max-h-[700px] overflow-y-auto">
                <table className="w-[90%]">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                        <th className="w-[14%] text-start">First Name</th>
                        <th className="w-[14%] text-start">Last Name</th>
                        <th className="w-[22%] text-start">Email</th>
                        <th className="w-[20%] text-start">School</th>
                        <th className="w-[14%] text-start">Language</th>
                        <th className="w-[14%] text-start"></th>
                        <th className="w-[14%] text-start"></th>
                    </thead>
                    <tbody>
                    {selectedContacts?.map((contact, index) => (
                        <tr key={index} className="odd:bg-gray-100 h-[48px]">
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={editContact.first_name}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    contact.first_name
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={editContact.last_name}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    contact.last_name
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type="email"
                                        name="email"
                                        className="w-[240px]"
                                        value={editContact.email}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    contact.email
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        name="school"
                                        value={editContact.school}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    contact.school
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <input
                                        type="text"
                                        name="language"
                                        value={editContact.language}
                                        onChange={handleChange}
                                    />
                                ) : (
                                    contact.language
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <a
                                        className="text-violet-800 cursor-pointer"
                                        onClick={handleSaveClick}
                                    >
                                        save
                                    </a>
                                ) : (
                                    <a
                                        className="text-violet-800 cursor-pointer"
                                        onClick={() => handleEditClick(index, contact)}
                                    >
                                        edit
                                    </a>
                                )}
                            </td>
                            <td>
                                {editIndex === index ? (
                                    <a>
                                    </a>
                                ) : (
                                    <a
                                        className="text-violet-800 cursor-pointer"
                                        onClick={() => archiveContact(index, contact)}
                                    >
                                        archive
                                    </a>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmailContacts;
