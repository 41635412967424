import React, {useEffect, useState} from 'react';
import {csrfFetch} from "../../../../store/csrf";

const AddContactList = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [listName, setListName] = useState(null);
    const [contactListIds, setContactListIds] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState(null);
    const [alphaPagination, setAlphaPagination] = useState(['all', 'a', 'b' ,'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']);
    const [selectedAlphaPagination, setSelectedAlphaPagination] = useState('all');
    const [alphaObject, setAlphaObject] = useState(null);

    useEffect(() => {
        const fetchContacts = async () => {
            const response = await csrfFetch('/api/emails/fetch-contacts');
            const data = await response.json();
            setContacts(data.response.contacts);
            setAlphaObject(data.response.alphabetObject)
            setSelectedContacts(data.response.alphabetObject["all"]);
        }
        if (!selectedContacts) {
            fetchContacts();
        };
    })

    const addNewContactList = async() => {
        const contact_list_name = listName;
        const ta_contact_ids = contactListIds;
        const response = await csrfFetch('/api/emails/add-contact-list', {
            method: 'POST',
            body: JSON.stringify({
                ta_contact_ids,
                contact_list_name
            }),
        })
        const data = await response.json();

        if (data) {
            setTimeout(() => {
                setShowModal(false);
            }, 1500)
        }
        return data;
    }

    const updateContactListIds = (contact) => {
        if (contactListIds.indexOf(contact.ta_contact_id) !== -1) {
            let updatedContactIds = contactListIds.filter(id => { return id !== contact.ta_contact_id});
            console.log(updatedContactIds);
            setContactListIds(updatedContactIds)
        } else {
            let updatedContactIds = [...contactListIds];
            updatedContactIds.push(contact.ta_contact_id);
            setContactListIds(updatedContactIds)
        }
        return;
    }

    const closeAddListModal = () => {
        setShowModal(false);
    }

    const setAlphaPage = (letter) => {
        setSelectedAlphaPagination(letter);
        setSelectedContacts(alphaObject[letter])
    }

    return (
        <div className="w-full px-4">
            <div className="flex flex-col items-center mt-12 max-h-[400px] overflow-y-auto">
                <div className="flex justify-start pl-6">
                    <form>
                        <label>
                            List Name
                        </label>
                        <input className="ml-2 border-black border-[.5px]"
                               type="text"
                               value={listName}
                               onChange={(e) => setListName(e.target.value)}
                        />
                    </form>
                </div>
                <p className="my-6">Add Contacts To List</p>
                <div className="mb-6 flex items-center">
                    <p className="font-owners-text text-sm mt-[1px] mr-1">Filter By Last Name: </p>
                    {alphaPagination.map((letter, index) => (
                        <a className="text-indigo-600 px-[2.5px] cursor-pointer" onClick={() => setAlphaPage(letter)}>{letter}</a>
                    ))
                    }
                </div>
                <table className="w-[90%] text-[10px]">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                        <th className="w-[6%] text-start"></th>
                        <th className="w-[10%] text-start">First Name</th>
                        <th className="w-[10%] text-start">Last Name</th>
                        <th className="w-[20%] text-start">Email</th>
                        <th className="w-[20%] text-start">School</th>
                        <th className="w-[14%] text-start">Language</th>
                    </thead>
                    <tbody>
                    {selectedContacts && selectedContacts?.map((contact, index) => (
                        <tr key={index} className="odd:bg-gray-100 h-[48px]">
                            <td>
                                <input
                                    onClick={() => updateContactListIds(contact)}
                                    type="checkbox"
                                />
                            </td>
                            <td>{contact.first_name}</td>
                            <td>{contact.last_name}</td>
                            <td>{contact.email}</td>
                            <td>{contact.school}</td>
                            <td>{contact.language}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="w-full flex justify-between px-8 my-4">
                <button onClick={closeAddListModal} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-[200px] justify-center rounded-md border border-transparent py-2 mr-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">Cancel</button>
                <button onClick={addNewContactList} className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-[200px] justify-center rounded-md border border-transparent py-2 ml-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">Add List</button>
            </div>
        </div>
    )
}

export default AddContactList;
