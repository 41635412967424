import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as sessionActions from "../../store/session";
import { csrfFetch } from '../../store/csrf.js';

const PaymentWebHook = () => {
  useEffect(() => {
    const handleWebhookEvent = async (event) => {
      try {
        // Forward the webhook event to the backend API
        await fetch('/testpayment', {
          method: 'POST',
          body: JSON.stringify(event),
        });
      } catch (error) {
        console.error('Error forwarding webhook event:', error);
      }
    };

    // Handle the webhook event when the component mounts
    handleWebhookEvent(window.location.href);

    // Clean up the component
    return () => {
      // Perform any necessary cleanup actions
    };
  }, []);

  return <div>Webhook Handler</div>;
};

export default PaymentWebHook;
