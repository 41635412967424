import React, {useState, useEffect} from 'react';
import { csrfFetch } from '../../store/csrf.js';
import {useDispatch, useSelector} from "react-redux";
import * as sessionActions from "../../store/session";
import TurnstileWidget from "../TurnstileWidget";


const LoginPopup = ({ showModal, setShowModal}) => {
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user);
    const [credential, setCredential] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [canAccess, setCanAccess] = useState(false);

    useEffect(() => {
        if (sessionUser) {
            setShowModal(false);
        }
    }, [sessionUser])

    useEffect(() => {
        if (isVerified) {
            setCanAccess(true);
        }
    }, [isVerified])

    const navToSignUp = (e) => {
        e.preventDefault();
        window.location.href = '/signup';
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors([]);
        if (!canAccess) {
            setErrors(["Please complete the security challenge"]);
            return;
        } else {
            return dispatch(sessionActions.login({credential, password}))
                .catch(async (res) => {
                    const data = await res.json();
                    if (data && data.errors) setErrors(data.errors);
                });
        }
    }

    return (
        <div className="fixed inset-0 w-screen bg-gray-500 bg-opacity-75 md:flex md:justify-center md:items-center">
            {showModal && <div className="sm:fixed bg-white h-[1000px] sm:w-screen md:h-[440px] md:w-[530px] rounded-lg shadow-xl">
                <div className="flex flex-col items-center p-10">
                    <p className="text-center font-owners-text font-bold text-3xl text-black tracking-[2px] animate-grow">Login To Use Generator</p>
                    <form onSubmit={handleSubmit}
                          className="flex flex-col justify-center items-center sm:w-[450px] mt-8">
                        <ul className="text-red-600">
                            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
                        </ul>
                        <label className="w-full">
                            <input
                                type="text"
                                value={credential}
                                onChange={(e) => setCredential(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Username or Email"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Password"
                            />
                        </label>
                        <div className="mt-6">
                            <TurnstileWidget setIsVerified={setIsVerified} />
                        </div>
                        <label className="flex justify-between items-center mt-4 mb-6">
                            <input type="checkbox" className="form-checkbox h-4 w-5 text-gray-600"></input>
                            <span className="font-owners-text font-bold tracking-wide text-sm">Remember me</span>
                            <a className="font-owners-text font-bold tracking-wide ml-8 text-indigo-600 text-sm" href="/resetpassword">Forgot your password?</a>
                        </label>
                        <div className="flex w-full items-center">
                        <button type="submit"
                                className="bg-[#E1341E] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                            <i className="fa-solid fa-lock color-gray-300 pt-1 mr-2"></i> Log In
                        </button>
                        <p className="px-4 font-owners-text font-bold tracking-wide">
                            or
                        </p>
                        <button onClick={(e) => navToSignUp(e)}
                            className="bg-zinc-300  group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                            Create A Free Account
                        </button>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    );
};

export default LoginPopup;
