import React, { useState } from "react";
import { csrfFetch } from '../../store/csrf.js';
import PrintButton from '../PrintButton';
import RichTextEditor from "./RichText"
import { ReactComponent as BackgroundImage } from "../Assets/background-home.svg"
import PricingDisplay from "../PricingDisplay";
import MainNav from "../MainNav";
import LoginPopup from "../LoginPopup";
import Footer from "../Footer";
import SupportModal from "../SupportModal";

function Search() {
  const [searchResults, setSearchResults] = useState("")
  const [topic, setTopic] = useState("")
  const [selectedRow, setSelectedRow] = useState(null);
  const [showRichtextModal, setShowRichtextModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [richText, setRichText] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPricingDisplay, setShowPricingDisplay] = useState(null);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showMobileMenuOption, setShowMobileMenuOption] = useState(false);

  const handleCloseModal = () => {
      setShowRichtextModal(false);
  };
  
  const handleCloseEditModal = async() => {
    setRichText(false)
    setShowEditModal(false);
      setShowRichtextModal(true);
  };

  const handleRichText = () => {
    setShowEditModal(true);
    setRichText(<RichTextEditor text={selectedRow.response} />);
    setShowRichtextModal(false);
  };


 const handleSubmit = async(e) => {
    e.preventDefault();

    const response = await csrfFetch("/api/search/topics", {
        method: "POST",
        body: JSON.stringify({
            topic
          }),
      });
      const data = await response.json();
      setSearchResults(data.entries)
      return data
  }

  function handleRowClick(event, row) {
     setShowRichtextModal(true);
     setSelectedRow(row);
  }

  return (
      <div>
        <MainNav setShowModal={setShowModal} showMobileMenuOption={showMobileMenuOption} setShowMobileMenuOption={setShowMobileMenuOption}/>
          {!showMobileMenuOption && <BackgroundImage className="w-screen absolute z-0"/>}
          {!showPricingDisplay && !showMobileMenuOption &&
              <div className="flex flex-col items-center h-screen w-screen pb-12 relative z-10">
                  {showModal && <LoginPopup className="z-40" showModal={showModal} setShowModal={setShowModal}/>}
            <h2 className="px-6 mt-16 mb-16 text-center font-rift-soft text-2xl md:text-[50px] text-white tracking-wider md:tracking-[14px] animate-grow">Browse The Library</h2>
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-8 md:px-2 w-[400px]">
            <label className="w-full text-sm mb-4">
                We've collected thousands of worksheets, lessons, and tests created by teachers just like you. Search for a subject, topic, or grade level:
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  required
                  className="h-10 border border-grey-500 px-4 w-full my-4"
                  placeholder="example: fractions"
                />
              </label>
                {!showModal && <button
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-[#E1341E] py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                    type="submit">Search</button>
                }            </form>
            {showRichtextModal ? (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded shadow-lg md:max-w-4xl max-h-screen overflow-auto flex flex-col">
                  <button
                    className="bg-red-500 self-end h-11 w-28 hover:bg-red-700 text-white font-bold py-2 px-4 mb-10 rounded float-right"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                  <p className="mb-10">{selectedRow.response}</p>
                  <div className="flex space-x-8">
                    <PrintButton content={selectedRow.response.replace(/\n/g, '<br>')} />
                    <button className="bg-red-500 hover:bg-red-700 text-white h-11 w-28 justify-end font-bold py-2 px-4 mt-4 mb-10 rounded float-right" onClick={handleRichText}>Edit</button>
                  </div>
                </div>
              </div>
            ) : null}
            {showEditModal ? (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 whitespace-pre-wrap">
                <div className="bg-white p-6 rounded shadow-lg md:max-w-4xl max-h-screen overflow-auto flex flex-col">
                  {richText}
                  <div className="flex justify-end">
                    <button className="bg-red-500 hover:bg-red-700 text-white w-28 mt-4 h-11 font-bold py-2 px-4 mb-10 rounded float-right" onClick={handleCloseEditModal}>Close</button>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="flex flex-wrap max-w-full justify-center">
            {!showModal && searchResults && searchResults.map((result) => {
                return <div className="bg-white rounded-lg px-10 py-10 mx-10 my-10 shadow-lg w-[500px] flex flex-col">
                      <p onClick={(event) => handleRowClick(event, result)} key={result.id}>{result.response.substring(0, 420)}...</p>
                      <br></br>
                      <span className="text-blue-700">click to view full</span>
                  </div>
              })}
              </div>
       </div>}
          {showSupportModal &&
              <SupportModal showSupportModal={showSupportModal} setShowSupportModal={setShowSupportModal} />
          }
      </div>
  );
}


export default Search;
