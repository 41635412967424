import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../../store/session.js"

const GradeSubject = ({ grade, setGrade, selectedSubject, setSelectedSubject, setShowNextButton }) => {
    const dispatch = useDispatch();

    if (selectedSubject.length > 0 && grade.length > 0) {
        setShowNextButton(true);
    } else {
        setShowNextButton(false);
    }

    const resetPage = () => {
        window.location.reload();
    }

    return (
        <div className="">
            <div className="py-2 flex flex-col items-center justify-center">
                <p>Select a TEK Subject:</p>
                <label className="w-full">
                    Enter a subject:
                    <input
                        type="text"
                        value={selectedSubject}
                        onChange={(e) => setSelectedSubject(e.target.value)}
                        className="h-10 border border-grey-100 px-4 w-full mb-4"
                        placeholder="Subject (e.g. persuasive writing)..."
                    />
                </label>
                {selectedSubject.length > 1 && selectedSubject.length < 10 &&
                    <p className="mb-2">***Specific subjects will produce better responses***</p>}
                <label className="w-full">
                    Enter a grade:
                    <input
                        type="text"
                        value={grade}
                        onChange={(e) => setGrade(e.target.value)}
                        className="h-10 border border-grey-100 px-4 w-full mb-4"
                        placeholder="E.g. 11th Grade..."
                    />
                </label>
            </div>
        </div>
    );
};

export default GradeSubject;
