import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import addCampaign from "./AddCampaign";
import AddCampaign from "./AddCampaign";

const EmailCampaigns = ({ setShowModal= {setShowModal}, setModalType= {setModalType} }) => {
    const [campaigns, setCampaigns] = useState(null);

    useEffect(() => {
        const fetchCampaigns = async() => {
            try {
                const response = await csrfFetch('/api/emails/fetch-email-campaigns');
                const data = await response.json();
                setCampaigns(data["emailCampaigns"]);
            } catch(error) {
                console.log(error);
            }
        };
        if (!campaigns) {
            fetchCampaigns();
        }
    })

    const addCampaign = (e) => {
        e.preventDefault();
        setShowModal(true);
        setModalType(<AddCampaign />)
    }

    return(
        <div className="w-full">
            <div className="flex justify-between px-8 pt-4 font-owners-text mt-2">
                <p className="font-bold">Campaigns</p>
                <button onClick={(e) => addCampaign(e)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add Campaign</button>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
                <table className="font-owners-text w-[90%] ">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                        <th className="w-[25%] px-8">id</th>
                        <th className="w-[25%] px-8">Name</th>
                        <th className="w-[25%] px-8">Steps</th>
                        <th className="w-[25%] px-2"># Scheduled</th>
                    </thead>
                    <tbody>
                    {campaigns?.map((campaign, index) => (
                        <tr key={index} className="text-center text-sm">
                            <td>{campaign.ta_email_campaign_id}</td>
                            <td>{campaign.email_campaign_name}</td>
                            <td>4</td>
                            <td>80</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmailCampaigns;
