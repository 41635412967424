import React, {useState} from "react";
import RichTextEditor from "../RichTextEditor";
import {csrfFetch} from "../../store/csrf";

const ContentEditor = ({contentId, content, setContent, contentType, closeEditor}) => {
    const [response, setResponse] = useState(null);
    const [saveSuccessMessage, setSaveSuccessMessage] = useState(false);

    async function saveContent() {
        let contentToSave = response;
        let data;

        const updatedContent = await csrfFetch("/api/teks/update-user-content", {
            method: "POST",
            body: JSON.stringify({
                contentId,
                contentType,
                contentToSave
            }),
        });
        data = await updatedContent.json();
        if (data) {
            setSaveSuccessMessage(true);
            setTimeout(() => setSaveSuccessMessage(false), 1500)
        }
    }

    return (
        <div className="flex flex-col w-screen fixed inset-0 bg-gray-500 bg-opacity-90 justify-center items-center">
            <div className="bg-zinc-200 w-screen md:w-[1200px] h-[85%] py-10 px-0 md:px-24 overflow-scroll">
                <div className="flex items-end justify-end w-full cursor-pointer hover:text-[#E1341E] my-4" onClick={closeEditor}>
                    <p className="font-owners-text tracking-wide">Close X</p>
                </div>
                <RichTextEditor response={content} setResponse={setResponse} />
            </div>
            <div className="px-2 py-2 flex items-center">
                <button onClick={saveContent} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">Save</button>
                {saveSuccessMessage && <p className="ml-2">Saved Successfully</p>}
            </div>
        </div>
    )
}

export default ContentEditor;
