import React from 'react';
import PricingDisplay from "../PricingDisplay";

const SignUpPage = () => {

    return (
        <div>
            <PricingDisplay heading={"Unlock Classroom Success With T.A. Unlimited"} text={"Sign Up For TeachersAIde Unlimited plan to save and edit your created content"} />
        </div>
    )
}

export default SignUpPage;
