import React, {useEffect, useState} from 'react';

const SavedPromptTable = (props) => {

    return (
        <div className="flex flex-col items-center">
            <h1>Your Sendable Materials:</h1>
            <table className="w-[80%] px-[24px] border-2 mt-10 shadow-lg shadow-black-500/50">
                <thead className='bg-slate-100'>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Content</th>
                    <th>Student Submissions</th>
                    <th>Link For Students</th>
                </thead>
                <tbody>
                    {/*only displays last 20 prompts*/}
                    {props.data?.slice(0,20).map(item => (
                        <tr key={item.user_saved_prompt_id}>
                            <td className="px-10 py-4">{item.user_saved_prompt_id}</td>
                            <td className="px-10 py-4">{item.title}</td>
                            <td className="px-10 py-4">{item.content.slice(0, 80) + '...'}</td>
                            <td className="px-10 py-4"><a href={`https://www.teachersaide.io/student-submissions/${item.user_saved_prompt_id}`} target="_blank" rel="noopener noreferrer" className="text-black-700 hover:text-indigo-700">View Student Submissions</a></td>
                            <td className="px-10 py-4"><a href={`${item.url}`} target="_blank" rel="noopener noreferrer" className="text-black-700 hover:text-indigo-700">View</a></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )    
}

export default SavedPromptTable;