import React, {useState} from 'react';
import EmailTemplates from "../index";
import EmailTextEditor from "../EmailTextEditor";
import {csrfFetch} from "../../../../store/csrf";

const AddEmailTemplate = ({setShowModal= {setShowModal}, setModalType= {setModalType}}) => {
    const [templateName, setTemplateName] = useState(null);
    const [subject, setSubject] = useState(null);
    const [html, setHTML] = useState(null);
    const [text, setText] = useState(null);

    const addEmailTemplate = async(e) => {
        e.preventDefault();
        const name = templateName;
        const content = html;
        const email_text = text;

        const response = await csrfFetch('/api/emails/add-email-template', {
            method: 'POST',
            body: JSON.stringify({
                name, subject, content, email_text
            })
        });

        const data = await response.json();
        if (data) {
            setTimeout(() => {
                console.log("It saved")
                setShowModal(false);
            }, 1200)
        } else {
            console.log("not able to add list")
        }
        return;
    }

    const closeAddTemplateModal = () => {
        setShowModal(false);
    }

    return (
        <div className="w-[800px]">
            <form>
                <label>
                    <input
                        type="text"
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        required
                        className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                        placeholder="Template Name"
                    />
                </label>
                <label>
                    <input
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                        placeholder="Email Subject"
                    />
                </label>
            </form>
            <EmailTextEditor html={html} setHTML={setHTML} text={text} setText={setText} />
                <button
                    className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                    onClick={() => closeAddTemplateModal()}
                >
                    Cancel
                </button>
                <div className="w-44"></div>
                <div className="flex mt-8">
                    <button
                        className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                        onClick={(e) => addEmailTemplate(e)}
                    >
                        Add Contact
                    </button>
            </div>
        </div>
    )
}

export default AddEmailTemplate;
