import React, { useRef, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './editor.css';

function RichTextEditor({ response, setResponse }) {
    const editorRef = useRef(null);
    const [text, setText] = useState(response);

    useEffect(() => {
        if (editorRef.current) {
            // Initialize Quill editor
            const editor = new Quill(editorRef.current, {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline'],
                        ['image', 'code-block']
                    ]
                },
                theme: 'snow'
            });
            editor.setText(response);
            setResponse(response)
            editor.on('text-change', () => {
                setText(editor.getText());
                setResponse(editor.getText())
            });

            // Clean up Quill editor
            return () => {
                editor.off('text-change');
                editorRef.current = null;
            };
        }
    }, [response]);

    return (
        <div>
            <div ref={editorRef}  input="x" class="editor-content" />
        </div>
    );
}

export default RichTextEditor;
