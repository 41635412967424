import React, { useState, useEffect } from 'react';

const CountdownInsert = () => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clean up the interval
    return () => clearInterval(interval);
  }, []);

  const calculateTimeLeft = () => {
    // Get current time and next Sunday 12 AM CST
    const now = new Date();
    const nextSunday = new Date(now);
    nextSunday.setHours(24 + (7 - now.getDay()) * 24, 0, 0, 0); // Set to next Sunday 12 AM
    nextSunday.setHours(nextSunday.getHours() - 6); // Adjust for CST

    // Calculate the difference
    const difference = +nextSunday - +now;

    // Convert the time to a readable format
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    // Format the time left as a string
    return `${timeLeft.days || 0}d ${timeLeft.hours || 0}h ${timeLeft.minutes || 0}m ${timeLeft.seconds || 0}s`;
  };

  return (
    <div className="flex justify-center mb-6">
      <p className="text-white font-bold tracking-tight text-white">Weekly Reset in: {timeLeft}</p>
    </div>
  );
};

export default CountdownInsert;
