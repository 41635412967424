import React from 'react';

function PrintButton(props) {
    const print = () => {
        let newWindow =  window.open();
        let printableArea = newWindow.document.createElement("div");
        printableArea.innerHTML = props.content;
        newWindow.document.body.appendChild(printableArea);
        newWindow.print();
        newWindow.document.body.removeChild(printableArea);
        newWindow.close();
    }
    return (
        <button onClick={print} className="bg-[#e32d48] hover:bg-red-700 text-white h-11 w-28 justify-end mt-4 font-bold py-2 px-4 rounded float-right">Print</button>
    );
}

export default PrintButton;