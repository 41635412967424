import React from 'react';
import Dashboard from "../Dashboard";
import Footer from "../Footer"


const WelcomeGuide = () => {
    return (
      <div>
        <div className={`flex flex-row w-full h-[1200px]`}>
         <Dashboard />
            <div className="py-4 px-12 pl-16">
              <h1 className="text-2xl font-bold mb-4">Welcome to TeachersAIde.io</h1>
              <p>Your gateway to the world's rapidly growing educational AI platform! Our user-friendly guide is designed to assist you in navigating and utilizing our platform effectively.</p>

              <h2 className="text-xl font-semibold mt-6 mb-2">Dashboard Overview</h2>
              <p>The dashboard, located on the left side of the screen, is your primary navigation tool. It provides links to various sections of the platform:</p>
              <ul className="list-disc list-inside ml-4">
                <li>Lesson Plans</li>
                <li>Worksheets</li>
                <li>Labs</li>
                <li>Tests</li>
                <li>Email Generator</li>
                <li>Settings</li>
              </ul>
              <p>To access a section, simply click on its link in the dashboard. You can also easily expand or collapse the dashboard by clicking the menu button located on the right side of the dashboard.</p>

              <h2 className="text-xl font-semibold mt-6 mb-2">Creating Lesson Plans</h2>
              <p>To generate a custom lesson plan:</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Go to the 'Lesson Plans' section via the dashboard.</li>
                <li>Fill in the form with the specific grade level and subject.</li>
                <li>Be precise in your subject input for optimal results. For instance, "9th, Chemistry, non-metal elements vs metals" yields a more tailored plan than a general "9th, science" input.</li>
              </ol>

              <h2 className="text-xl font-semibold mt-6 mb-2">Generating Worksheets</h2>
              <p>For worksheet creation:</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Navigate to the 'Worksheets' section from the dashboard.</li>
                <li>Enter the grade level, subject, and select a worksheet type from the dropdown menu.</li>
                <li>Detailed subject descriptions ensure more effective worksheets.</li>
              </ol>

              <h2 className="text-xl font-semibold mt-6 mb-2">Developing Tests</h2>
              <p>To create tests:</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Access the 'Tests' section through the dashboard.</li>
                <li>Input the grade level, subject, and desired number of questions.</li>
                <li>Specific subject details enhance test quality. Note: While creating tests with over 30 questions is possible, shorter tests (under 30 questions) are recommended for better generation quality.</li>
              </ol>

              <h2 className="text-xl font-semibold mt-6 mb-2">Lab Experiments</h2>
              <p>To generate lab activities:</p>
              <ol className="list-decimal list-inside ml-4">
                <li>Visit the 'Labs' section via the dashboard.</li>
                <li>Complete the form with the appropriate grade level and subject.</li>
              </ol>

              <p className="mt-6">This guide is a starting point for exploring TeachersAIde.io. If you have further questions or need assistance, please contact our support team. Welcome to an enhanced teaching experience with TeachersAIde.io!</p>
            </div>
        </div>
        <Footer />
    </div>
    );
  };
  
  export default WelcomeGuide;