import React, { useState, useRef } from 'react';
import CreateContentForm from './ContentTypes/CreateContentForm.js';
import LabSuppliesForm from "./LabSupplies/lab-supplies-form";
import LabWarningPopup from "../../LabWarningPopup";

const SelectContentType = ({ userId, selectedTeks, grade, selectedSubject, setPageCount, pageCount, language, setLanguage, testQuestionTypes, setTestQuestionTypes, numberOfQuestions, setNumberOfQuestions, contentType, setContentType, worksheetTypes, setWorksheetTypes, setSelectedEquipment, setSelectedChemicals, setShowCreateButton, setShowPricingDisplay }) => {
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [showQuestionInput, setShowQuestionInput] = useState(false);
    const [showTestQuestionTypes, setShowTestQuestionTypes] = useState(false);
    const [showWorksheetTypes, setShowWorksheetTypes] = useState(false);
    const [showLabForms, setShowLabForms] = useState(false);
    const [showLabWarningPopup, setShowLabWarningPopup] = useState(false);
    const divRef = useRef(null);

    const updateContentType = (type) => {
        setShowCreateButton(true);
        switch(type) {
            case 'freestyle-lessonplan':
                setContentType(type)
                setShowWorksheetTypes(false);
                setShowQuestionInput(false);
                setShowTestQuestionTypes(false);
                setShowLabForms(false);
                return setSelectedContentType(<CreateContentForm userId={userId} setPageCount={setPageCount} pageCount={pageCount} language={language} setShowPricingDisplay={setShowPricingDisplay} />)
            case 'freestyle-worksheet':
                setContentType(type)
                setShowWorksheetTypes(true);
                setShowQuestionInput(false);
                setShowLabForms(false);
                return setSelectedContentType(<CreateContentForm userId={userId} setPageCount={setPageCount} pageCount={pageCount} language={language} numberOfQuestions={numberOfQuestions} worksheetTypes={worksheetTypes} setShowPricingDisplay={setShowPricingDisplay} />)
            case 'freestyle-test':
                setContentType(type);
                setShowQuestionInput(true);
                setShowTestQuestionTypes(true);
                setShowWorksheetTypes(false);
                setShowLabForms(false);
                return setSelectedContentType(<CreateContentForm userId={userId} setPageCount={setPageCount} pageCount={pageCount} language={language} numberOfQuestions={numberOfQuestions} testQuestionTypes={testQuestionTypes} setShowPricingDisplay={setShowPricingDisplay} />)
            case 'freestyle-lab':
                setShowLabWarningPopup(true);
                setContentType(type);
                setShowQuestionInput(false);
                setShowTestQuestionTypes(false);
                setShowWorksheetTypes(false);
                setShowLabForms(true);
                return setSelectedContentType(<CreateContentForm userId={userId} setPageCount={setPageCount} pageCount={pageCount} language={language} numberOfQuestions={numberOfQuestions} testQuestionTypes={testQuestionTypes} setShowPricingDisplay={setShowPricingDisplay} />)
            default: return setSelectedContentType(<CreateContentForm userId={userId} grade={grade} selectedSubject={selectedSubject} selectedTeks={selectedTeks} setShowPricingDisplay={setShowPricingDisplay} />)
        }
        handleScrollToBottom();
    }
    const handleScrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const updateNumberOfQuestions = (numberOfQuestions) => {
        setNumberOfQuestions(numberOfQuestions);
    }

    const updateTestQuestionTypes = (questionType) => {
        if (testQuestionTypes.indexOf(questionType) === -1) {
            let questionTypes = [...testQuestionTypes];
            questionTypes.push(questionType);
            setTestQuestionTypes(questionTypes);
        } else {
            let questionTypes = testQuestionTypes.filter(type => {
                return type !== questionType;
            })
            setTestQuestionTypes(questionTypes)
        }
    }

    const updateWorksheetTypes = (worksheetType) => {
        if (worksheetTypes.indexOf(worksheetType) === -1) {
            let currentworksheetTypes = [...worksheetTypes];
            currentworksheetTypes.push(worksheetType);
            setWorksheetTypes(currentworksheetTypes);
        } else {
            let currentworksheetTypes = testQuestionTypes.filter(type => {
                return type !== worksheetType;
            })
            setWorksheetTypes(currentworksheetTypes)
        }
    }

    return (
        <div ref={divRef} className="flex flex-col items-center">
            {showLabWarningPopup &&
                <LabWarningPopup setShowLabWarningPopup={setShowLabWarningPopup} />
            }
            <div className="my-6">
                <p className="text-center mb-2">Select A Type To Create</p>
                <div className="sm:flex sm:flex-col md:flex md:flex-row justify-evenly">
                    <div>
                        <button onClick={() => updateContentType("freestyle-lessonplan")} className="justify-center py-2 px-4 mx-4 mt-4 w-[180px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700">Lesson Plan</button>
                    </div>
                    <div>
                        <button onClick={() => updateContentType("freestyle-worksheet")} className="justify-center py-2 px-4 mx-4 mt-4 w-[180px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700">Worksheet</button>
                    </div>
                    <div>
                        <button onClick={() => updateContentType("freestyle-test")} className="justify-center py-2 px-4 mx-4 mt-4 w-[180px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700">Test</button>
                    </div>
                    <div>
                        <button onClick={() => updateContentType("freestyle-lab")} className="justify-center py-2 px-4 mx-4 mt-4 w-[180px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700">Lab</button>
                    </div>
                </div>
            </div>
            {selectedContentType && <div className="flex flex-col items-center my-2 mb-4">
                <p>Select A Language</p>
                <select onChange={e => setLanguage(e.target.value)} className="mt-2 w-32 h-8">
                    <option value="English">English</option>
                    <option value="Spanish">Spanish</option>
                    <option value="French">French</option>
                    <option value="German">German</option>
                </select>
            </div>}

            {showQuestionInput && <div className="flex flex-col items-center my-2 mb-4">
                <p className="h-8">Number Of Questions</p>
                <input
                    type="number"
                    value={numberOfQuestions}
                    onChange={e => updateNumberOfQuestions(e.target.value)}
                ></input>
            </div>}

            {showTestQuestionTypes && <div className="flex flex-col justify-center items-center">
                <p className="mb-1">Question Types:</p>
                <div className="flex flex-col justify-start">
                    <div>
                        <input className="mr-1" type="checkbox" value="Multiple Choice" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Multiple Choice</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Short Answer" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Short Answer</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Essay" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Essay</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Fill in the blank" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Fill in the blank</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="True or False" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>True or False</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Term Matching" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Term Matching</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Writing Prompts" onChange={(e) => updateTestQuestionTypes(e.target.value)} /><label>Writing Prompts</label>
                    </div>
                </div>
            </div>}
            {showWorksheetTypes && <div className="flex flex-col justify-center items-center my-4">
                <p>Worksheet Sections:</p>
                <div className="flex flex-col ml-[54px] items-start">
                    <div className="mt-1">
                        <input className="mr-1" type="checkbox" value="Handout" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Handout</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Topic Summary" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Topic Summary</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="In-depth Topic Analysis" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>In-depth Topic Analysis</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Vocabulary Blanks" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Vocabulary Blanks</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Term Matching" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Term Matching</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Open Ended Questions" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Open Ended Questions</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Practice Problems" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Practice Problems</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Fill in the blank" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Fill in the blank</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Word Search" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Word Search</label>
                    </div>
                    <div>
                        <input className="mr-1" type="checkbox" value="Writing Prompts" onChange={(e) => updateWorksheetTypes(e.target.value)} /><label>Writing Prompts</label>
                    </div>
                </div>
            </div>}
            { showLabForms &&
                <div>
                    <LabSuppliesForm setSelectedEquipment={setSelectedEquipment} setSelectedChemicals={setSelectedChemicals} />
                </div>
            }
            {selectedContentType}
        </div>
    )
}

export default SelectContentType;
