import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as sessionActions from "../../store/session";
import { csrfFetch } from '../../store/csrf.js';
import Dashboard from "../Dashboard";
import PaginatedTable from "./paginatedTable.js"
import SavedPromptTable from "./savedPromptTable.js"


function ProfilePage() {
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [userLessons, setUserLessons] = useState([]);
  const [userSavedPrompts, setUserSavedPrompts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await csrfFetch("/api/lessons/get-submitted-user-prompts", {
        method: "GET"
      });
      const data = await response.json();

      let userPromptsArray = data.submittedPrompts.map(prompt => {
        return prompt.response;
      })

    setUserLessons(data.submittedPrompts)
      return
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchSavedData() {
      const response = await csrfFetch("/api/users/get-user-saved-prompts", {
        method: "POST",
        body: JSON.stringify({
          user_email: user.email
        })
      });
      const data = await response.json();

      setUserSavedPrompts(data.userSavedPrompts)
      return
    }

    fetchSavedData();
  }, []);

    const user = sessionUser;
    if (!user) {
      return <Redirect to="/login" />
    }

  return (
    <div className="flex h-[4000px] max-h-[3500px] flex-row ">
      <Dashboard />
      <div>
        {sessionUser && <p className="p-4">Username: {sessionUser["username"]}</p>}
        {<SavedPromptTable data={userSavedPrompts} /> }

        {<PaginatedTable data={userLessons} itemsPerPage={18} />}
      </div>
    </div>
  );
}


export default ProfilePage;
