import React, { useRef, useEffect, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import './editor.css';

function EmailTextEditor({html= {html}, setHTML= {setHTML}, text= {text}, setText= {setText}}) {
    const editorRef = useRef(null);


    useEffect(() => {
        if (editorRef.current) {
            // Initialize Quill editor
            const editor = new Quill(editorRef.current, {
                modules: {
                    toolbar: [
                        [{ header: [1, 2, 3, false] }],
                        [{ font: [] }],
                        [{ size: ['small', false, 'large', 'huge'] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        [{ script: 'sub'}, { script: 'super' }],
                        [{ list: 'ordered'}, { list: 'bullet' }],
                        [{ indent: '-1'}, { indent: '+1' }],
                        [{ direction: 'rtl' }],
                        [{ align: [] }],
                        ['link', 'image', 'video', 'formula'],
                        ['blockquote', 'code-block'],
                        ['clean']  // Removes formatting
                    ]
                },
                theme: 'snow'
            });

            editor.on('text-change', () => {
                setText(editor.getText())
                setHTML(editor.root.innerHTML)
            });


            // Clean up Quill editor
            return () => {
                editor.off('text-change');
                editorRef.current = null;
            };
        }
    }, []);

    const loghtml = () => {
        console.log(html)
    }
    return (
        <div>
            <div ref={editorRef}  input="x" class="editor-content" />
            <button onClick={loghtml}>Log</button>
        </div>
    );
}

export default EmailTextEditor;
