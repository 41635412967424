import LessonPlan from '../components/LessonPlanArchv';
import { csrfFetch } from './csrf';
import { v4 as uuidv4 } from 'uuid';

let uuid = 0;
uuid = uuidv4();

const SET_USER = 'session/setUser';
const REMOVE_USER = 'session/removeUser';
const SET_LESSONPLAN = 'session/setLessonplan';
const REMOVE_LESSONPLAN = 'session/removeLessonplan';
const SET_LESSONS = 'session/setLessons';
const REMOVE_LESSONS = 'session/removeLessons';
const SET_TEST= 'session/setTest';
const REMOVE_TEST = 'session/setTest';
const SET_BLOGPOST= 'session/setBlogpost';
const REMOVE_BLOGPOST = 'session/setBlogpost';
const SET_WORKSHEET = 'session/setTest';
const REMOVE_WORKSHEET = 'session/setTest';
const SET_LAB = 'session/setLab';
const REMOVE_LAB = 'session/setLab';
const SET_EMAIL = 'session/setEmail';
const REMOVE_EMAIL = 'session/setEmail';
const SET_USEDPROMPT = 'session/setUsedprompt';
const REMOVE_USEDPROMPT= 'session/setUsedprompt';
const SET_USERSUBSCRIPTION = 'session/setUsersubscription';
const REMOVE_USERSUBSCRIPTION= 'session/removeUsersubscription';
const SET_USERSAVEDPROMPT = 'session/setUsersubscription';
const REMOVE_USERSAVEDPROMPT = 'session/removeUsersubscription';
const SET_STUDENTPROMPTSUBMISSION = 'session/setStudentPromptSubmission';
const REMOVE_STUDENTPROMPTSUBMISSION  = 'session/removeStudentPromptSubmission';
const SET_TEKSUBJECTS = 'session/setTekSubjects';
const REMOVE_TEKSUBJECTS  = 'session/removeTekSubjects';
const SET_TEKGRADES = 'session/setTekGrades';
const REMOVE_TEKGRADES  = 'session/removeTekGrades';
const SET_TEKS = 'session/setTeks';
const REMOVE_TEKS  = 'session/removeTeks';

const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

const setLessonplan = (lessonplan) => {
    return {
      type: SET_LESSONPLAN,
      payload: lessonplan,
    };
  };

  const setTest = (test) => {
    return {
      type: SET_TEST,
      payload: test,
    };
  };

  const setWorksheet = (worksheet) => {
    return {
      type: SET_WORKSHEET,
      payload: worksheet,
    };
  };

  const setUsedprompt = (usedprompt) => {
    return {
      type: SET_USEDPROMPT,
      payload: usedprompt,
    };
  };

  const setUsersubscription = (usersubscription) => {
    return {
      type: SET_USERSUBSCRIPTION,
      payload: usersubscription,
    };
  };

  const setLab = (lab) => {
    return {
      type: SET_LAB,
      payload: lab,
    };
  };

  const setEmail = (email) => {
    return {
      type: SET_EMAIL,
      payload: email,
    };
  };

  const setBlogpost = (blogpost) => {
    return {
      type: SET_BLOGPOST,
      payload: blogpost,
    };
  };

  const setTekSubjects = (subjects) => {
    return {
      type: SET_TEKSUBJECTS,
      payload: subjects,
    };
  };

  const setTekGrades = (grades) => {
    return {
      type: SET_TEKGRADES,
      payload: grades,
    };
  };

  const setTeks = (teks) => {
    return {
      type: SET_TEKS,
      payload: teks,
    };
  };

  const setUserSavedPrompt = (userSavedPrompt) => {
    return {
      type: SET_USERSAVEDPROMPT,
      payload: userSavedPrompt,
    };
  };

  const setStudentPromptSubmission = (studentPromptSubmission) => {
    return {
      type: SET_STUDENTPROMPTSUBMISSION,
      payload: studentPromptSubmission,
    };
  };

const removeUser = () => {
  return {
    type: REMOVE_USER,
  };
};

const removeLessonplan = () => {
    return {
      type: REMOVE_LESSONPLAN,
    };
  };

  const removeTest = () => {
    return {
      type: REMOVE_TEST
    };
  };

  const removeWorksheet = () => {
    return {
      type: REMOVE_WORKSHEET
    };
  };

  const removeLab = () => {
    return {
      type: REMOVE_LAB
    };
  };

  const removeTekSubjects = () => {
    return {
      type: REMOVE_TEKSUBJECTS
    };
  };

    const removeTekGrades = () => {
      return {
        type: REMOVE_TEKGRADES
      };
    };

  const removeTeks = () => {
    return {
      type: REMOVE_TEKS
    };
  };

  const removeEmail = () => {
    return {
      type: REMOVE_EMAIL
    };
  };

  const setLessons = (lessons) => {
    return {
      type: SET_LESSONS,
      payload: lessons,
    };
  };

  const removeLessons = () => {
    return {
      type: REMOVE_LESSONS
    };
  };

  const removeBlogpost = () => {
    return {
      type: REMOVE_BLOGPOST
    };
  };

  const removeUsedprompt = (usedprompt) => {
    return {
      type: SET_USEDPROMPT,
      payload: usedprompt,
    };
  };

  const removeUserSavedPrompt = (studentPromptSubmission) => {
    return {
      type: REMOVE_USERSAVEDPROMPT
    };
  };

  const removeStudentPromptSubmission = (studentPromptSubmission) => {
    return {
      type: REMOVE_STUDENTPROMPTSUBMISSION,
    };
  };

export const login = (user) => async (dispatch) => {
  const { credential, password } = user;
  const response = await csrfFetch('/api/session', {
    method: 'POST',
    body: JSON.stringify({
      credential,
      password,
    }),
  });
  const data = await response.json();
  dispatch(setUser(data.user));
  return response;
};

const initialState = { user: null };
const sessionReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_USER:
      newState = Object.assign({}, state);
      newState.user = action.payload;
      return newState;
    case REMOVE_USER:
      newState = Object.assign({}, state);
      newState.user = null;
      return newState;
      case SET_LESSONPLAN:
        newState = Object.assign({}, state);
        newState.lessonplan = action.payload;
        return newState;
      case REMOVE_LESSONPLAN:
        newState = Object.assign({}, state);
        newState.lessonplan = null;
        return newState;
        case SET_TEST:
          newState = Object.assign({}, state);
          newState.test = action.payload;
          return newState;
        case REMOVE_TEST:
          newState = Object.assign({}, state);
          newState.test = null;
          return newState;
        case SET_WORKSHEET:
          newState = Object.assign({}, state);
          newState.worksheet = action.payload;
          return newState;
        case REMOVE_WORKSHEET:
          newState = Object.assign({}, state);
          newState.worksheet = null;
          return newState;
          case SET_LAB:
            newState = Object.assign({}, state);
            newState.lab = action.payload;
            return newState;
          case REMOVE_LAB:
            newState = Object.assign({}, state);
            newState.lab = null;
            return newState;
        case SET_LESSONS:
          newState = Object.assign({}, state);
          newState.lessons = action.payload;
          return newState;
        case REMOVE_LESSONS:
          newState = Object.assign({}, state);
          newState.lessons = null;
          return newState;
        case SET_BLOGPOST:
          newState = Object.assign({}, state);
          newState.blogpost = action.payload;
          return newState;
        case REMOVE_BLOGPOST:
          newState = Object.assign({}, state);
          newState.blogpost = null;
          return newState;
          case SET_EMAIL:
            newState = Object.assign({}, state);
            newState.email = action.payload;
            return newState;
          case REMOVE_EMAIL:
            newState = Object.assign({}, state);
            newState.email = null;
            return newState;
        case SET_USEDPROMPT:
          newState = Object.assign({}, state);
          newState.usedprompt = action.payload;
          return newState;
        case REMOVE_USEDPROMPT:
          newState = Object.assign({}, state);
          newState.usedprompt = null;
          return newState;
          case SET_USERSUBSCRIPTION:
            newState = Object.assign({}, state);
            newState.usersubscription = action.payload;
            return newState;
          case REMOVE_USERSUBSCRIPTION:
            newState = Object.assign({}, state);
            newState.usersubscription = null;
        case SET_USERSAVEDPROMPT:
          newState = Object.assign({}, state);
          newState.userSavedPrompt = action.payload;
          return newState;
        case REMOVE_USERSAVEDPROMPT:
          newState = Object.assign({}, state);
          newState.userSavedPrompt = null;
          return newState;
    case SET_TEKSUBJECTS:
      newState = Object.assign({}, state);
      newState.subjects = action.payload;
      return newState;
    case REMOVE_TEKSUBJECTS:
      newState = Object.assign({}, state);
      newState.subjects = null;
      return newState;
    case SET_TEKGRADES:
      newState = Object.assign({}, state);
      newState.grades = action.payload;
      return newState;
    case REMOVE_TEKGRADES:
      newState = Object.assign({}, state);
      newState.grades = null;
      return newState;
    case SET_TEKS:
      newState = Object.assign({}, state);
      newState.teks = action.payload;
      return newState;
    case REMOVE_TEKS:
      newState = Object.assign({}, state);
      newState.teks = null;
      return newState;
       // return newState;
    default:
      return state;
  }
};

export const restoreUser = () => async dispatch => {
    const response = await csrfFetch('/api/session');
    const data = await response.json();
    dispatch(setUser(data.user));
    return response;
  };

  export const signup = (user) => async (dispatch) => {
    const { first_name, last_name, username, email, password } = user;
    const response = await csrfFetch("/api/users", {
      method: "POST",
      body: JSON.stringify({
        first_name,
        last_name,
        username,
        email,
        password,
      }),
    });
    const data = await response.json();
    dispatch(setUser(data.user));
    return response;
  };

  export const logout = () => async (dispatch) => {
    const response = await csrfFetch('/api/session', {
      method: 'DELETE',
    });
    dispatch(removeUser());
    return response;
  };

  export const fetchLessonPlan = (lessonplan) => async (dispatch) => {
    return lessonplan
    /*
    const response = await csrfFetch("/api/lessons/get-submittedPromptId", {
      method: "GET",
    });
    const data = await response.json();
    dispatch(setWorksheet(data.response['response']));
    return data.response['response'];
    */
  };
  

  export const fetchWorksheet = (worksheet) => async (dispatch) => {
    const response = await csrfFetch(`/api/lessons/get-submittedPromptId/${uuid}`, {
      method: "GET",
    });

    const data = await response.json();
    dispatch(setWorksheet(data.response['response']));
    return data.response['response'];
  };


  export const createWorksheet = (worksheet) => async (dispatch) => {
    const { grade, subject, topic, worksheetType, selectedOptions, userId } = worksheet;
    try {
      const response = await csrfFetch('/api/lessons/get-db-worksheet', {
        method: "POST",
        body: JSON.stringify({
          grade,
          subject,
          topic,
          worksheetType,
          selectedOptions,
          userId,
          uuid
        }),
      });

      const data = await response.json();
      return data;

    } catch (error) {
      const errorMsg = `https://www.teachersaide.io/submitted-prompts/${uuid}`;
      return errorMsg;
    }
  }


  export const createLab = (lab) => async (dispatch) => {
    const { grade, subject, userId } = lab;
    try {
      const response = await csrfFetch('/api/labs/create-db-lab', {
        method: "POST",
        body: JSON.stringify({
          grade,
          subject,
          userId,
          uuid
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      const errorMsg = `https://www.teachersaide.io/submitted-prompts/${uuid}`;
      return errorMsg;
    }
  }


  export const createLessonplan = (lessonplan) => async (dispatch) => {
    const { grade, subject, userId } = lessonplan;
    try {
      const response = await csrfFetch('/api/lessons/create-db-lessonplan', {
        method: "POST",
        body: JSON.stringify({
          grade,
          subject,
          userId,
          uuid
        }),
      });
      dispatch(setLessonplan(data.lessonplan));

      throw new Error('threw an error');
      const data = await response.json();
      return data;
    } catch (error) {
      const errorMsg = `https://www.teachersaide.io/submitted-prompts/${uuid}`;
      return errorMsg;
    }
  }


  export const createTest = (test) => async (dispatch) => {
    const { grade, subject, topic, numberOfQuestions, selectedOptions } = test;
    try {
      const response = await csrfFetch('/api/tests/create-db-test', {
        method: "POST",
        body: JSON.stringify({
          grade,
          subject,
          topic,
          numberOfQuestions,
          selectedOptions,
          uuid
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      const errorMsg = `https://www.teachersaide.io/submitted-prompts/${uuid}`;
      return errorMsg;
    }
  }

  export const fetchLessonplanTeks = (lessonplan) => async (dispatch) => {
    removeLessonplan();
    const { grade, knowledge, skill } = lessonplan;
    const response = await csrfFetch("/api/lessons/get-lessonplan-tek", {
      method: "POST",
      body: JSON.stringify({
        grade,
        knowledge, 
        skill
      }),
      timeout: 1000, // 5 seconds timeout
    });
    const data = await response.json();
    dispatch(setLessonplan(data.lessonplan));
    return data.lessonplan;
  };


  export const fetchTest = (test) => async (dispatch) => {
    const { grade, subject, numberOfQuestions } = test;
    const response = await csrfFetch("/api/tests/get-test", {
      method: "POST",
      body: JSON.stringify({
        grade, 
        subject,
        numberOfQuestions
      }),
    });
    const data = await response.json();
    dispatch(setTest(data.test));
    return data.test;
  };

  export const addLessonplan = (lessonplan) => async (dispatch) => {
    const { planBody } = lessonplan;
    const response = await csrfFetch("/api/lessons", {
      method: "POST",
      body: JSON.stringify({
        planBody
      }),
    });
    const data = await response.json();
    return response;
  };

  export const restoreLessons = () => async dispatch => {
    const response = await csrfFetch("/api/lessons/lessonplans", {
      method: "GET"
    });
    const data = await response.json();
    return response;
  };

  export const getTekSubjects = () => async dispatch => {
    const response = await csrfFetch("/api/teks/get-subjects", {
      method: "GET"
    });
    const data = await response.json();
    dispatch(setTekSubjects(data.subjects));
    return response;
  };

  export const fetchTekGrades = (subject) => async dispatch => {
    const response = await csrfFetch("/api/teks/grade", {
      method: "POST",
      body: JSON.stringify({
        subject
      }),
    });
    const data = await response.json();
    dispatch(setTekGrades(data.grades));
    return response;
  };

  export const fetchTeks = (grade, subject) => async dispatch => {
    const response = await csrfFetch("/api/teks/fetch-teks", {
      method: "POST",
      body: JSON.stringify({
        grade,
        subject
      }),
    });
    const data = await response.json();
    dispatch(setTeks(data.teks));
    return response;
  };

  export const addTest = (test) => async (dispatch) => {
    const { testBody } = test;
    const response = await csrfFetch("/api/tests", {
      method: "POST",
      body: JSON.stringify({
        testBody
      }),
    });
    const data = await response.json();
    return response;
  };

  export const restoreTests = () => async dispatch => {
    const response = await csrfFetch("/api/tests/", {
      method: "GET"
    });
    const data = await response.json();
    return response;
  };

  export const addBlogpost = (blogpost) => async (dispatch) => {
    const {         
      slug, 
      title, 
      description, 
      content, 
      ogTitle, 
      ogDescription, 
      ogImage, 
      canonicalUrl, 
      author, 
      categories,
      featuredImage, 
      tags,
      password } = blogpost;
    const response = await csrfFetch("/api/posts", {
      method: "POST",
      body: JSON.stringify({
        slug, 
        title, 
        description, 
        content, 
        ogTitle, 
        ogDescription, 
        ogImage, 
        canonicalUrl, 
        author, 
        categories,
        featuredImage, 
        tags,
        password
      }),
    });
    const data = await response.json();
    return response;
  };

  export const generateEmail = (email) => async (dispatch) => {
    const { emailBody } = email;
    try {
      const response = await csrfFetch('/api/emails/get-db-email', {
        method: "POST",
        body: JSON.stringify({
         emailBody
        }),
      });

      const data = await response.json();

      return data;

    } catch (error) {
      return { response: null };
    }
  }


  export const getUsedprompt = (userId) => async (dispatch) => {
    const user_id = userId;
    try {
      const response = await csrfFetch('/api/usedprompts/get-usedprompts', {
        method: "POST",
        body: JSON.stringify({
          userId: user_id
        }),
      })

      const data = await response.json();
      dispatch(setUsedprompt(data));
      return response;

    } catch (error) {
      return { response: null };
    }
  };

  export const getSubscription = (userId) => async (dispatch) => {
    const user_id = userId;
    try {
      const response = await csrfFetch('/api/users/get-usersubscription', {
        method: "POST",
        body: JSON.stringify({
          userId: user_id
        }),
      })

      const data = await response.json();
      dispatch(setUsersubscription(data));
      return response;

    } catch (error) {
      return { response: null };
    }
  };

  export const generateStudentLink = (user_email, title, grade, subject, content, url) => async (dispatch) => {
    try {
      const response = await csrfFetch('/api/users/save-user-prompt', {
        method: "POST",
        body: JSON.stringify({
          user_email, 
          title, 
          grade, 
          subject, 
          content, 
          url
        }),
      })

      const data = await response.json()
      return response;
    } catch (error) {
      //message: error
    }
  };


  export const submitStudentPrompt = (student_first_name, student_last_name, student_email, school_name, promptId, content) => async (dispatch) => {

    try {
      const response = await csrfFetch('/api/students/submit-student-prompt', {
        method: "POST",
        body: JSON.stringify({
          student_first_name, 
          student_last_name, 
          student_email, 
          school_name,
          promptId,
          content
        }),
      })

      const data = await response.json()
      return response;
    } catch (error) {
      //message: error
    }
  };


export default sessionReducer;
