import React, { useState, useEffect, useRef } from 'react';
import GradeSubject from './FreestyleSelector/grade-subject.js'
import SelectContentType from "./FreestyleSelector/select-content-type";
import GeneratedContent from "./FreestyleSelector/generated-content.js";
import MainNav from "../MainNav";
import * as sessionActions from "../../store/session";
import {useDispatch, useSelector} from "react-redux";
import { ReactComponent as BackgroundImage } from "../Assets/background-home.svg"
import LoginPopup from "../LoginPopup";
import PricingDisplay from "../PricingDisplay";
import SupportModal from "../SupportModal";
import Footer from "../Footer";
import {NavLink} from "react-router-dom";

const FreeStyle = () => {
    const sessionUser = useSelector((state) => state.session.user);
    const [userId, setUserId] = useState(null);
    const teks = useSelector((state) => state.session.teks);
    const [teksListed, setTeksListed] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [selectedTeks, setSelectedTeks] = useState([]);
    const [language, setLanguage] = useState("English");
    const [grade, setGrade] = useState("");
    const [selectedSubject, setSelectedSubject] = useState("");
    const [response, setResponse] = useState("Select A Subject");
    const [formTheme, setFormTheme] = useState("bg-white");
    const [worksheetTypes, setWorksheetTypes] = useState([]);
    const [testQuestionTypes, setTestQuestionTypes] = useState([]);
    const [numberOfQuestions, setNumberOfQuestions] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState([]);
    const [selectedChemicals, setSelectedChemicals] = useState([]);
    const [contentType, setContentType] = useState(null);
    const [showNextButton, setShowNextButton] = useState(false);
    const [showCreateButton, setShowCreateButton] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const [showPricingDisplay, setShowPricingDisplay] = useState(null);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showMobileMenuOption, setShowMobileMenuOption] = useState(false);

    useEffect( () => {
        setTeksListed(teks)
    }, [teks])

    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser.id);
                dispatch(sessionActions.getSubscription(sessionUser.id));
            }
        }

        setUserAndSubscription();
    }, [sessionUser])

    const updatePageCount = (direction) => {
        let count = pageCount;
        if (direction === "up") {
            count += 1;
            setPageCount(count)
            setShowNextButton(false);
        } else {
            count -= 1;
            setPageCount(count);
            setShowCreateButton(false);
            if (count === 0) {
                setShowNextButton(false);
                setSelectedTeks([]);
            }
        }
    }

    const toggleGenerator = async(e) => {
        e.preventDefault();
        if (sessionUser) {
            let count = pageCount + 1;
            setPageCount(count);
        } else {
            //fire pop up
            setShowModal(true);
        }
    };

    const showLoginPopup = () => {
        setShowModal(true);
    }

    const logout = (e) => {
        e.preventDefault();
        dispatch(sessionActions.logout());
    };

    return (
        <div className="sm:max-w-[98%] md:max-w-full overflow-x-hidden">
            {showPricingDisplay &&
                <PricingDisplay heading={"Unlock Unlimited Usage With T.A. Unlimited"} text={"You're out of attempts for the month, sign up for TeachersAIde Unlimited plan to unlock unlimited usage."} />
            }
            {!showPricingDisplay &&
                <MainNav setShowModal={setShowModal} showMobileMenuOption={showMobileMenuOption} setShowMobileMenuOption={setShowMobileMenuOption} />
            }
            {!showPricingDisplay && !showMobileMenuOption &&
                <BackgroundImage className="w-screen absolute z-0" />
            }
            {!showPricingDisplay && !showSupportModal && !showMobileMenuOption &&
                <div className="flex flex-col items-center h-screen w-screen pb-12 relative z-10">
                {showModal && <LoginPopup className="z-40" showModal={showModal} setShowModal={setShowModal}/>}
                <h1 className="px-6 mt-16 mb-16 text-center font-rift-soft text-2xl md:text-[50px] text-white tracking-wider md:tracking-[14px] animate-grow">AI Generator For Teachers</h1>
                { !showModal && !showSupportModal &&
                <div className="w-[97%] md:w-[940px] h-[600px] border-white shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] overflow-scroll z-20">
                    <div className="w-full h-12 bg-[#E1341E] opacity-90 flex justify-between px-10 sticky top-0">
                        { pageCount > 0 && <button onClick={() => updatePageCount("down")} className="text-white">Back</button>}
                        <div>{/*I'm here for spacing when the back button is hidden*/}</div>
                        { pageCount < 1 && showNextButton && <button onClick={() => updatePageCount("up")} className="animate-pulse text-white">Next</button> }
                        { pageCount === 1 && showCreateButton && <button onClick={(e) => toggleGenerator(e)} className="animate-pulse text-white">Create</button> }
                        { pageCount === 2 &&
                            <div>
                                <p>Select theme</p>
                                <div className="flex justify-center">
                                    <div className="h-[20px] w-[20px] mx-1 bg-custom-gradient cursor-pointer" onClick={() => setFormTheme('')}></div>
                                    <div className="h-[20px] w-[20px] mx-1 bg-white cursor-pointer" onClick={() => setFormTheme('bg-white')}></div>
                                    <div className="h-[20px] w-[20px] mx-1 bg-black cursor-pointer" onClick={() => setFormTheme('bg-black text-white')}></div>
                                </div>
                            </div> }
                    </div>
                    { pageCount === 0 &&
                        <div className="flex flex-col justify-center items-center mt-10">
                            <p className="px-6 mt-4 mb-10 text-center font-rift-soft text-2xl text-white tracking-[2px]">Use AI to generate lessons, tests, worksheets, and labs:</p>
                            <GradeSubject grade={grade} setGrade={setGrade} selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} setShowNextButton={setShowNextButton} />
                        </div>
                        }
                    { pageCount === 1 && <SelectContentType userId={userId} selectedTeks={selectedTeks} grade={grade} selectedSubject={selectedSubject} setPageCount={setPageCount} pageCount={pageCount} language={language} setLanguage={setLanguage} testQuestionTypes={testQuestionTypes} setTestQuestionTypes={setTestQuestionTypes} numberOfQuestions={numberOfQuestions} setNumberOfQuestions={setNumberOfQuestions} contentType={contentType} setContentType={setContentType} worksheetTypes={worksheetTypes} setWorksheetTypes={setWorksheetTypes} setSelectedEquipment={setSelectedEquipment} setSelectedChemicals={setSelectedChemicals} setShowCreateButton={setShowCreateButton} setShowPricingDisplay={setShowPricingDisplay} /> }
                    { pageCount === 2 && <GeneratedContent selectedTeks={selectedTeks} grade={grade} selectedSubject={selectedSubject} userId={userId} language={language} formTheme={formTheme} testQuestionTypes={testQuestionTypes} numberOfQuestions={numberOfQuestions} contentType={contentType} worksheetTypes={worksheetTypes} selectedEquipment={selectedEquipment} selectedChemicals={selectedChemicals}/> }
                </div>}
            </div>}
            {showSupportModal &&
                <SupportModal showSupportModal={showSupportModal} setShowSupportModal={setShowSupportModal} />
            }
            {!showMobileMenuOption &&
                <Footer setShowSupportModal={setShowSupportModal}/>
            }
        </div>
    );
};

export default FreeStyle;
