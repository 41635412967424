import React, {useState} from 'react';

const LabWarningPopup = ({setShowLabWarningPopup}) => {
    const [isChecked, setIsChecked] = useState(false);

    const updateAgreementCheckbox = () => {
        setIsChecked(!isChecked);
    }

    const closeLabWarning = () => {
        setShowLabWarningPopup(false);
    }

    return (
        <div className="md:fixed md:inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-xl sm:w-[90%] md:w-[500px]">
                <div className="flex flex-col items-center p-10">
                    <p className="text-center font-owners-text font-bold text-3xl text-black tracking-[2px] animate-grow my-2">NOT FOR CLASSROOM USE!</p>
                    <p className="text-center font-owners-text font-bold text-black tracking-[2px] animate-grow my-2">Labs can be dangerous and using an AI generator can lead to unexpected results. Lab content created from this generator has not been reviewed by scientific professionals and at no time should any or any part of a created lab be used in real life. This is for research purposes only.</p>
                    <div className="my-2">
                        <input type="checkbox" onChange={updateAgreementCheckbox} checked={isChecked} />
                        <label className="font-owners-text text-s"> I understand this generator is for research purposes
                            only and I agree to NEVER under any circumstance use any part of the
                            created content in real life and/or in a classroom setting
                            and I accept all risks and liabilities and the site policy.</label>
                    </div>
                    <div className="flex w-full items-center my-2">
                        {isChecked &&
                            <button onClick={closeLabWarning}
                                    className="bg-[#E1341E] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                                Accept
                            </button>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LabWarningPopup;
