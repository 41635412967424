import {React, useState, useEffect} from 'react';

const TeksDisplay = ({ teks, setSelectedTeks, selectedTeks, setShowNextButton }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [showWarningMsg, setShowWarningMsg] = useState(false);

    useEffect(() => {
        if (selectedTeks.length > 0) {
            setShowNextButton(true);
        } else {
            setShowNextButton(false);
        }
    }, [selectedTeks])

    const handleCheckboxChange = (tek) => {
        if (selectedTeks.indexOf(tek) == -1) {
            if (selectedTeks.length === 3) {
                setShowWarningMsg(true);
                setTimeout(() => {
                    setShowWarningMsg(false);
                }, 2000)
            } else {
                setSelectedTeks([...selectedTeks, tek])
            }
        } else {
            let filteredTeks = selectedTeks.filter(selectedTek => {
                return selectedTek != tek
            })
            setSelectedTeks(filteredTeks)
        }
        setCheckedItems(prevState => ({
            ...prevState,
            [tek]: !prevState[tek]
        }));
    };


    return (
        <div className="flex flex-col">
            {  showWarningMsg && <div className="place-self-end w-[260px] h-[60px] m-1 bg-red-500 rounded opacity-90">
                    <p className="py-1 px-4 text-white">Can only select 3 TEKs, please deselect one.</p>
                </div>
            }
            <p className="text-center my-4 text-white">Select Up To Three Teks:</p>
            <div className="grid grid-cols-3 w-[97%]">
                {teks?.map((tek, index) => (
                    <div onClick={() => handleCheckboxChange(tek.tek)}
                         className="sm:w-[200px] md:w-[280px] h-[100px] border-2 rounded-md m-2 px-4 py-1 shadow-lg bg-slate-100 cursor-pointer">
                        <input
                            type="checkbox"
                            value={tek.tek}
                            checked={selectedTeks.indexOf(tek.tek) !== -1 || false}
                            onChange={() => handleCheckboxChange(tek.tek)}
                        />
                        <label
                            className="cursor-pointer px-0 md:px-4 text-[8px] md:text-xs">{tek.tek.length < 120 ? tek.tek : tek.tek.slice(0, 120) + "..."}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TeksDisplay;
