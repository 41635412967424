import React, {useState} from 'react';
import {csrfFetch} from "../../../../store/csrf";

const AddContact = ({ setShowModal={setShowModal}, setModalType= {setModalType} }) => {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [school, setSchool] = useState(null);
    const [language, setLanguage] = useState(null);
    const [addContactMessage, setAddContactMessage] = useState(null);

    const addNewContact = async(e) => {
        e.preventDefault();
        const first_name = firstName;
        const last_name = lastName;
        const ta_inspirer_id = 1;
        const response = await csrfFetch('/api/emails/add-contact', {
            method: "POST",
            body: JSON.stringify({
                first_name,
                last_name,
                email,
                school,
                language,
                ta_inspirer_id
            }),
        });

        const data = await response.json();
        setAddContactMessage(JSON.stringify(data));
        setTimeout(() => {
            setShowModal(false);
        }, 2000)
    }

    const closeAddContactModal = () => {
        setShowModal(false);
        setModalType(null);
    }

    return (
        <div>
            <div className="w-full flex justify-center">
                <div className="absolute top-12 rounded-md z-10 bg-white flex flex-col items-center justify-center w-[500px] h-[400px]">
                    <p>Add Contact</p>
                    <form className="flex flex-col items-center justify-center px-12" onSubmit={addNewContact}
                          className="flex flex-col justify-center items-center sm:w-[450px] mt-8">
                        <label className="w-full">
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="First Name"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Last Name"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Email"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="text"
                                value={school}
                                onChange={(e) => setSchool(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="School"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="text"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                required
                                className="font-owners-text w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Language"
                            />
                        </label>
                        <div className="flex mt-8">
                            <button
                                className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                //onClick={(e) => addNewContact(e)}
                            >
                                Add Contact
                            </button>
                            <div className="w-44"></div>
                            <button
                                className="bg-zinc-300 group relative font-owners-text tracking-wide flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                                onClick={() => closeAddContactModal()}
                            >
                                Cancel
                            </button>
                            <p>{addContactMessage}</p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddContact;
