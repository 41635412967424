import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as sessionActions from "../../../store/session.js"

const GradeSubject = ({ setGrade, selectedSubject, setSelectedSubject, setShowNextButton }) => {
    const dispatch = useDispatch();
    const subjects = useSelector((state) => state.session.subjects);
    const grades = useSelector((state) => state.session.grades);
    const [checkedItems, setCheckedItems] = useState({});

    useEffect( () => {
        if (!subjects) {
            dispatch(sessionActions.getTekSubjects())
        }
    }, [subjects])

    const fetchGrades = async(e) => {
        const subject = e;
        setSelectedSubject(subject)
        await dispatch(sessionActions.fetchTekGrades(subject));
    }

    const fetchTeks = async(e) => {
        const grade = e;
        setGrade(grade)
        setShowNextButton(true);
        await dispatch(sessionActions.fetchTeks(grade, selectedSubject));
        setCheckedItems({});
    }

    const resetPage = () => {
        window.location.reload();
    }

    return (
        <div className="">
            <div className="py-2 flex flex-col items-center justify-center">
                <p>Select a TEK Subject:</p>
                {subjects && <div>
                    <select className="w-[400px]" onChange={(e) => fetchGrades(e.target.value)}>
                        <option>
                            Select A Subject
                        </option>
                        {subjects.map((subject, index) => (
                            <option key={index} value={subject.subject}>
                                {subject.subject}
                            </option>
                        ))}
                    </select>
                </div>
                }
                {grades && <div className="py-2">
                    <p className="flex flex-col items-center justify-center">Select a Grade:</p>
                    <select className="w-[400px]" onChange={(e) => fetchTeks(e.target.value)}>
                        <option>
                            Select A Grade
                        </option>
                        {grades.map((grade, index) => (
                            <option key={index} value={grade.grade}>
                                {grade.grade}
                            </option>
                        ))}
                    </select>
                </div>
                }
            </div>
        </div>
    );
};

export default GradeSubject;
