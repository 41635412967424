import React, {useState} from 'react';
import { csrfFetch } from '../../store/csrf.js';

const SupportModal = ({ showSupportModal, setShowSupportModal }) => {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const closeSuccessMessage = async(e) => {
        e.preventDefault();
        setShowSuccessMessage(false)
        window.location.reload();
    }

    const sendSupportEmail = async(e) => {
        e.preventDefault();
        const response = await csrfFetch("/api/emails/support-email", {
          method: "POST",
          body: JSON.stringify({
            email,
            message
          }),
        });
        const data = await response.json();
        if (data) {
            setShowSuccessMessage(true)
            setShowSupportModal(false)
        }
        return
    }    

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      {showSupportModal && <div className="absolute bg-white rounded-lg shadow-xl">
        <div className="flex flex-col items-center p-10">
          <form onSubmit={sendSupportEmail} className="flex flex-col w-[400px]">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Need support? Drop us a line:</h3>

       <label className="text-sm text-gray-500 mt-4">
                Email:
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="h-10 border border-grey-100 px-4 w-full mb-4"
          placeholder=""
        />
        </label>
        <label className="text-sm text-gray-500">
                Message:
        <textarea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="h-10 border border-grey-100 px-4 w-full mb-4"
          placeholder=""
        />
        </label>
            <div className="mt-2">
              <p className="text-sm text-gray-500 mb-4">
                Let us know what issue you're having.
              </p>
            </div>
            <div className="flex flex-row justify-center">
                <span className="mt-4">
                  <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-zinc-400 hover:bg-zinc-300">
                    Go back to dashboard
                  </button>
                </span>
                <span className="mt-4 ml-4">
                  <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700" onClick={sendSupportEmail}>
                    Submit Support Form
                  </button>
                </span>
            </div>

          </form>
        </div>
      </div>}

      {showSuccessMessage && <div className="bg-white rounded-lg shadow-xl">
        <div className="flex flex-col items-center p-10">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">Message sent successfully!</h3>
        <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700" onClick={closeSuccessMessage}>
            Close
        </button>
        </div>
      </div>}
    </div>
  );
};

export default SupportModal;
