import {React, useEffect, useState} from 'react';
import LoginPopup from "../../../LoginPopup";
import {useSelector} from "react-redux";
import {csrfFetch} from "../../../../store/csrf";

const CreateContentForm = ({ userId, setPageCount, pageCount, setShowPricingDisplay }) => {
    const [showModal, setShowModal] = useState(false);
    const sessionUser = useSelector(state => state.session.user);
    const sessionSubscription = useSelector(state => state.session.usersubscription)
    const [outOfAttempts, setOutOfAttempts] = useState(false);

    useEffect(() => {
        async function fetchAttemptUsage() {
            if (userId && !sessionSubscription?.is_active) {
                const response = await csrfFetch("/api/teks/fetch-user-content-count", {
                    method: "POST",
                    body: JSON.stringify({
                        userId,
                    }),
                });
                const data = await response.json()

                if (data.userContentCount >= 10) {
                    setOutOfAttempts(true);
                }
            }
        }
        fetchAttemptUsage();
    })

    const toggleGenerator = async(e) => {
        e.preventDefault();
        if (sessionUser && !outOfAttempts) {
            let count = pageCount + 1;
            setPageCount(count);
        } else if (outOfAttempts) {
            setShowPricingDisplay(true);
            return;
        } else {
            //fire pop up
            setShowModal(true);
            return;
        }
    };

    return(
        <div className="my-4 flex flex-col items-center">
            <button className="justify-center py-2 px-4 mx-4 mt-2 w-[120px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700" onClick={(e) => toggleGenerator(e)}>Create</button>
            {showModal && <LoginPopup showModal={showModal} setShowModal={setShowModal}/>}
        </div>
    )
}

export default CreateContentForm;
