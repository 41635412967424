export const labEquipment = [
    "Beaker",
    "Erlenmeyer Flask",
    "Graduated Cylinder",
    "Test Tube",
    "Pipette",
    "Burette",
    "Volumetric Flask",
    "Petri Dish",
    "Glass Rod",
    "Bunsen Burner",
    "Microscope",
    "Centrifuge",
    "Spectrophotometer",
    "Balance (Scale)",
    "Magnetic Stirrer",
    "Hot Plate",
    "Thermometer",
    "pH Meter",
    "Laboratory Oven",
    "Desiccator",
    "Crucible",
    "Mortar and Pestle",
    "Watch Glass",
    "Funnel",
    "Filter Paper",
    "Reagent Bottle",
    "Tongs",
    "Tweezers",
    "Spatula",
    "Dropper",
    "Stirring Rod",
    "Test Tube Rack",
    "Test Tube Holder",
    "Test Tube Brush",
    "Wash Bottle",
    "Burette Clamp",
    "Retort Stand",
    "Clamps",
    "Ring Stand",
    "Wire Gauze",
    "Tripod",
    "Evaporating Dish",
    "Separatory Funnel",
    "Condenser",
    "Distillation Apparatus",
    "Pipette Bulb",
    "Pipette Filler",
    "Rubber Stopper",
    "Parafilm",
    "Glass Slide",
    "Cover Slip",
    "Incubator",
    "Water Bath",
    "Heat Gun",
    "Ice Bath",
    "Lab Refrigerator",
    "Lab Freezer",
    "Autoclave",
    "Cuvette",
    "Laboratory Blender",
    "Homogenizer",
    "Microtome",
    "Microplate",
    "Microcentrifuge Tubes",
    "Electrophoresis Apparatus",
    "Gel Documentation System",
    "PCR Machine (Thermocycler)",
    "Spectrometer",
    "Viscometer",
    "Hygrometer",
    "Barometer",
    "Manometer",
    "Refractometer",
    "Colorimeter",
    "Vortex Mixer",
    "Shaker",
    "Rotary Evaporator",
    "Chromatography Column",
    "Gas Chromatograph",
    "Liquid Chromatograph",
    "NMR Spectrometer",
    "X-Ray Diffractometer",
    "Atomic Absorption Spectrometer",
    "Mass Spectrometer"
];

export const labChemicals = [
    "Acetone",
    "Ethanol",
    "Methanol",
    "Isopropanol",
    "Chloroform",
    "Benzene",
    "Toluene",
    "Hexane",
    "Sodium Chloride (NaCl)",
    "Potassium Chloride (KCl)",
    "Calcium Chloride (CaCl2)",
    "Magnesium Sulfate (MgSO4)",
    "Ammonium Chloride (NH4Cl)",
    "Sodium Hydroxide (NaOH)",
    "Potassium Hydroxide (KOH)",
    "Calcium Hydroxide (Ca(OH)2)",
    "Sulfuric Acid (H2SO4)",
    "Hydrochloric Acid (HCl)",
    "Nitric Acid (HNO3)",
    "Acetic Acid (CH3COOH)",
    "Phosphoric Acid (H3PO4)",
    "Citric Acid",
    "Formic Acid",
    "Sodium Bicarbonate (NaHCO3)",
    "Sodium Carbonate (Na2CO3)",
    "Potassium Carbonate (K2CO3)",
    "Calcium Carbonate (CaCO3)",
    "Sodium Sulfate (Na2SO4)",
    "Potassium Nitrate (KNO3)",
    "Sodium Nitrate (NaNO3)",
    "Ammonium Nitrate (NH4NO3)",
    "Copper Sulfate (CuSO4)",
    "Iron(III) Chloride (FeCl3)",
    "Zinc Chloride (ZnCl2)",
    "Silver Nitrate (AgNO3)",
    "Magnesium Chloride (MgCl2)",
    "Sodium Phosphate (Na3PO4)",
    "Ammonium Sulfate ((NH4)2SO4)",
    "Sodium Thiosulfate (Na2S2O3)",
    "Barium Chloride (BaCl2)",
    "Ethylenediaminetetraacetic Acid (EDTA)",
    "Hydrogen Peroxide (H2O2)",
    "Glycerol",
    "Ethylene Glycol",
    "Phenol",
    "Sodium Acetate (CH3COONa)",
    "Potassium Permanganate (KMnO4)",
    "Sodium Hypochlorite (NaOCl)",
    "Ammonium Hydroxide (NH4OH)",
    "Boric Acid (H3BO3)",
    "Urea",
    "Gelatin",
    "Agar",
    "Sucrose",
    "Glucose",
    "Fructose",
    "Lactose",
    "Dextrose",
    "Sodium Citrate",
    "Potassium Dichromate (K2Cr2O7)",
    "Calcium Hypochlorite (Ca(OCl)2)",
    "Ferrous Sulfate (FeSO4)",
    "Starch",
    "Tartaric Acid",
    "Bromothymol Blue",
    "Phenolphthalein",
    "Methylene Blue",
    "Crystal Violet",
    "Iodine",
    "Sodium Bisulfate (NaHSO4)",
    "Ammonium Bicarbonate (NH4HCO3)",
    "Lithium Chloride (LiCl)",
    "Calcium Oxide (CaO)",
    "Sodium Metabisulfite (Na2S2O5)",
    "Potassium Iodide (KI)",
    "Ferric Ammonium Citrate",
    "Copper Nitrate (Cu(NO3)2)",
    "Lead Nitrate (Pb(NO3)2)",
    "Aluminum Chloride (AlCl3)",
    "Nickel Sulfate (NiSO4)",
    "Sodium Borate (Na2B4O7)",
    "Ammonium Phosphate ((NH4)3PO4)",
    "Chromic Acid (H2CrO4)",
    "Oxalic Acid",
    "Benzoic Acid",
    "Sodium Benzoate",
    "Silica Gel",
    "Sodium Sulfite (Na2SO3)",
    "Calcium Nitrate (Ca(NO3)2)"
];
