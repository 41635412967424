import {React, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import TeksTrixEditor from "../TeksTrixEditor/index.js"
import {csrfFetch} from "../../../store/csrf";

const GeneratedContent = ({ selectedTeks, grade, selectedSubject, userId, language, formTheme, testQuestionTypes, numberOfQuestions, contentType, worksheetTypes, selectedEquipment, selectedChemicals }) => {
    const [generateResponse, setGenerateResponse] = useState(true);
    const [uuid, setUuid] = useState(uuidv4())
    const [rws, setRws] = useState(null);
    const wsUrl = 'wss://www.teachersaide.io/'; //; //'ws://localhost:8000/';
    const [response, setResponse] = useState("");
    const [editResponse, setEditResponse] = useState(false);
    const [userFreestyleLessonplanId, setUserFreestyleLessonplanId] = useState(null);
    const [userFreestyleTestId, setUserFreestyleTestId] = useState(null);
    const [userFreestyleWorksheetId, setUserFreestyleWorksheetId] = useState(null);
    const [userFreestyleLabId, setUserFreestyleLabId] = useState(null);
    const [newSave, setNewSave] = useState(true);
    const [saveSuccessMessage, setSaveSuccessMessage] = useState(false);

    useEffect(() => {
        if (generateResponse) {
            initiateChat()
            setGenerateResponse(false);
        }
    }, [generateResponse])
    const handleMessageEvent = (event) => {
        setResponse(prevResponse => prevResponse + event.data.replace("**", ""));

        if (event.data === "You're out of weekly attempts.") {
        }
        if (rws) {
            rws.close();
            setRws(null);
        }
    };

    const initiateChat = async() => {
        if (!rws) {
            const socket = new WebSocket(wsUrl);
            const type = contentType;
            socket.addEventListener('open', () => {
                const teks = selectedTeks;
                const subject = selectedSubject;

                socket.send(JSON.stringify({ grade, subject, userId, uuid, type, teks, language, testQuestionTypes, numberOfQuestions, worksheetTypes, selectedEquipment, selectedChemicals }));
            });

            socket.addEventListener('message', handleMessageEvent);

            setRws(socket);
            //let url = `https://www.teachersaide.io/submitted-prompts/${uuid}`

        } else {
            rws.send(JSON.stringify({ grade, selectedTeks, userId, uuid }));
        }
    };

    async function saveContent() {
        let subject = selectedSubject;
        let tek = JSON.stringify([...selectedTeks]);
        // Create a temporary div element to parse the HTML content
        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = response;
        let content = tempDiv.textContent || tempDiv.innerText || "";
        let data;

        switch (contentType) {
            case 'freestyle-lessonplan':
                const userFreestyleLessonplan = await csrfFetch("/api/teks/save-user-freestyle-lessonplan", {
                    method: "POST",
                    body: JSON.stringify({
                        userFreestyleLessonplanId,
                        userId,
                        subject,
                        grade,
                        content,
                        language
                    }),
                });
                data = await userFreestyleLessonplan.json();
                if (newSave) {
                    setNewSave(false);
                    setUserFreestyleLessonplanId(data.userFreestyleLessonplanId);
                }

                if (data) {
                    setSaveSuccessMessage(true);
                    setTimeout(() => setSaveSuccessMessage(false), 1500)
                }
                break;

            case 'freestyle-worksheet':
                const userFreestyleWorksheet = await csrfFetch("/api/teks/save-user-freestyle-worksheet", {
                    method: "POST",
                    body: JSON.stringify({
                        userFreestyleWorksheetId,
                        userId,
                        subject,
                        grade,
                        content,
                        language,
                        worksheetTypes,
                    }),
                });
                data = await userFreestyleWorksheet.json();
                if (newSave) {
                    setNewSave(false);
                    setUserFreestyleWorksheetId(data.userFreestyleWorksheetId);
                }

                if (data) {
                    setSaveSuccessMessage(true);
                    setTimeout(() => setSaveSuccessMessage(false), 1500)
                }
                break;

            case 'freestyle-test':
                const userFreestyleTest = await csrfFetch("/api/teks/save-user-freestyle-test", {
                    method: "POST",
                    body: JSON.stringify({
                        userFreestyleTestId,
                        userId,
                        subject,
                        grade,
                        content,
                        language,
                        testQuestionTypes,
                        numberOfQuestions
                    }),
                });
                data = await userFreestyleTest.json();
                if (newSave) {
                    setNewSave(false);
                    setUserFreestyleTestId(data.userFreestyleTestId);
                }

                if (data) {
                    setSaveSuccessMessage(true);
                    setTimeout(() => setSaveSuccessMessage(false), 1500)
                }
                break;

            case 'freestyle-lab':
                const userFreestyleLab = await csrfFetch("/api/teks/save-user-freestyle-lab", {
                    method: "POST",
                    body: JSON.stringify({
                        userFreestyleLabId,
                        userId,
                        subject,
                        grade,
                        content,
                        language,
                        selectedEquipment,
                        selectedChemicals
                    }),
                });
                data = await userFreestyleLab.json();
                if (newSave) {
                    setNewSave(false);
                    setUserFreestyleLabId(data.userFreestyleLabId);
                }

                if (data) {
                    setSaveSuccessMessage(true);
                    setTimeout(() => setSaveSuccessMessage(false), 1500)
                }
                break;
        }

        return;
    }

    const print = () => {
        let newWindow =  window.open();
        let printableArea = newWindow.document.createElement("div");
        printableArea.innerHTML = `<pre className="mt-12">${response}</pre>`;
        newWindow.document.body.appendChild(printableArea);
        newWindow.print();
        newWindow.document.body.removeChild(printableArea);
        newWindow.close();
    }

    return(
        <div className="flex flex-col items-center" className={formTheme}>
            <div className="flex justify-end w-full pr-14 pt-6">
                <button className="justify-center py-2 px-2 mx-2 mt-2 w-[100px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700" onClick={() => setEditResponse(true)}>Edit</button>
                <button className="justify-center py-2 mx-2 mt-2 w-[100px] border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#E1341E] hover:bg-indigo-700" onClick={print}>Print</button>
            </div>
            { !editResponse && <pre
                className="w-screen md:w-[700px] whitespace-pre-wrap px-10 pt-16 pb-24 md:ml-32 md:pl-[20px] md:px-0 mb-10">
                {response}
            </pre>}
            { editResponse &&
                <div>
                    <TeksTrixEditor response={response} setResponse={setResponse} />
                    <div className="px-2 py-2 flex items-center">
                        <button onClick={saveContent} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">Save</button>
                        {saveSuccessMessage && <p className="ml-2">Saved Successfully</p>}
                    </div>
                </div>
            }
        </div>
    )
}

export default GeneratedContent;
