import React from 'react';
import { ReactComponent as Checkmark } from "./checkmark.svg"
import { ReactComponent as CheckmarkWhite } from "./checkmark-white.svg"
import { ReactComponent as StripeIcon } from "./stripe-icon.svg"

const PricingDisplay = ({heading, text}) => {

    const checkoutPage = (subType) => {
        if (subType == 'monthly') {
            window.open("https://buy.stripe.com/bIYg0413r2W995C3cc", '_blank');
        } else if (subType == 'annual') {
            window.open("https://buy.stripe.com/3cs016bI58gtdlS5kl", '_blank');
        } else {
            window.location = '/signup'
        }
    }

    return (
        <div className="bg-diagonal-gradient from-[#3B006F] to-[#000000] w-screen h-[1400px]">
            <div className="w-full flex flex-col justify-center items-center">
                <p className="text-center mt-32 text-white text-4xl font-bold tracking-wide">{heading}</p>
                <p className="sm:px-16 md:px-0 text-center mt-6 text-white opacity-60 text-xl font-owners-text tracking-wide">{text}</p>
            </div>
            <div className="mt-16 sm:flex sm:flex-col md:flex md:flex-row justify-center">
                <div id="free-plan-price">
                    <div className="mb-8 mt-12 px-12 w-[420px] h-[520px] bg-diagonal-gradient from-[#3B006F] to-[#000000] rounded-2xl">
                        <p className="pt-16 text-white font-owners-text">Free Plan</p>
                        <div className="mt-2 flex items-center">
                            <p className="text-white text-4xl font-bold mr-2 font-owners-text tracking-wide">$0</p>
                            <div>
                                <p className="text-sm text-white font-owners-text tracking-wide">Always Free</p>
                                <p className="text-sm text-white font-owners-text tracking-wide">No CC required</p>
                            </div>
                        </div>
                        <div className="w-full h-20 flex justify-center">
                            <button onClick={() => checkoutPage('free')} className="bg-[#6757f7] inline-flex items-center justify-center w-[320px] mt-8 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-zinc-300">
                                Sign Up Now
                            </button>
                        </div>
                        <div className="pl-2 mt-8 text-white">
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2 text-white" />
                                <p className="font-owners-text tracking-wide">Access to Tek Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to Freestyle Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Limit 10 Monthly Generations</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">No Credit Card required</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="monthly-plan-price">
                    <div className="mb-8 mt-4 px-12 w-[420px] h-[520px] bg-white rounded-2xl">
                        <p className="pt-16 font-owners-text tracking-wide">Monthly Plan</p>
                        <div className="mt-2 flex items-center">
                            <p className="text-4xl font-bold mr-2 font-owners-text tracking-wide">$1.99</p>
                            <div>
                                <p className="text-sm font-owners-text tracking-wide">USD</p>
                                <p className="text-sm font-owners-text tracking-wide">Billed Monthly</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-center">
                            <button onClick={() => checkoutPage('monthly')} className="bg-[#6757f7] inline-flex items-center justify-center w-[320px] mt-8 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-zinc-300">
                                Checkout Now With <StripeIcon className="ml-2 w-[44px] h-[32px]" />
                            </button>
                        </div>
                        <div className="pl-2 mt-8">
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <Checkmark className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Unlimited Generation Usage</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <Checkmark className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to Tek Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <Checkmark className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to Freestyle Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <Checkmark className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Save and edit created content</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <Checkmark className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to over 10,000 pre-made content</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="annual-plan-price">
                    <div className="mb-8 mt-12 px-12 w-[420px] h-[520px] bg-diagonal-gradient from-[#3B006F] to-[#000000] rounded-2xl">
                        <p className="pt-16 text-white font-owners-text tracking-wide">Annual Plan</p>
                        <div className="mt-2 flex items-center">
                            <p className="text-4xl font-bold mr-2 text-white font-owners-text tracking-wide">$12.99</p>
                            <div>
                                <p className="text-sm text-white font-owners-text tracking-wide">USD</p>
                                <p className="text-sm text-white font-owners-text tracking-wide">Billed Annually</p>
                            </div>
                        </div>
                        <div className="w-full flex justify-center">
                            <button onClick={() => checkoutPage('annual')} className="bg-[#6757f7] inline-flex items-center justify-center w-[320px] mt-8 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-zinc-300">
                                Checkout Now With <StripeIcon className="ml-2 w-[44px] h-[32px]" />
                            </button>
                        </div>
                        <div className="pl-2 mt-8 text-white">
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Unlimited Generation Usage</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to Tek Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to Freestyle Generator</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Save and edit created content</p>
                            </div>
                            <div className="flex border-b-[.5px] border-white border-opacity-50 py-3">
                                <CheckmarkWhite className="w-[20px] h-[20px] mr-2" />
                                <p className="font-owners-text tracking-wide">Access to over 10,000 pre-made content</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PricingDisplay;
