import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MainNav from '../MainNav';
import { ReactComponent as BackgroundImage } from "../Assets/background-home.svg"
import Footer from '../Footer';
import * as sessionActions from '../../store/session';
import SupportModal from "../SupportModal";

const MyAccount = () => {
    const sessionUser = useSelector((state) => state.session.user);
    const subscription = useSelector((state) => state.session.usersubscription);
    const [userId, setUserId] = useState(null);
    const url = `https://billing.stripe.com/p/login/4gwbLT7gz05W7vy4gg`;
    const upgradeUrl = 'https://buy.stripe.com/bIYg0413r2W995C3cc'
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showMobileMenuOption, setShowMobileMenuOption] = useState(false);

    useEffect(() => {
        async function setUserAndSubscription() {
            if (sessionUser) {
                setUserId(sessionUser?.id);
                await dispatch(sessionActions.getSubscription(sessionUser?.id));
            }
        }

        setUserAndSubscription();
    }, [sessionUser, dispatch]);

    return (
        <div className="overflow-x-hidden">
            <MainNav setShowModal={setShowModal} showMobileMenuOption={showMobileMenuOption} setShowMobileMenuOption={setShowMobileMenuOption} />
            {!showSupportModal && !showMobileMenuOption && <BackgroundImage className="w-screen absolute z-0"/>}
            {!showSupportModal && !showMobileMenuOption && <div className="pl-12 flex flex-col items-center h-screen w-screen pb-12 relative z-10">
                <div className="w-screen md:w-[800px] mt-12 border-b-[.5px] border-white py-8 flex items-center px-12">
                    <p className="font-owners-text font-bold text-2xl">Username:</p>
                    <p className="ml-12 pt-1 text-center font-owners-text text-xl font-bold">{sessionUser?.username}</p>
                </div>
                <div className="w-screen md:w-[800px] mt-12 border-b-[.5px] border-white py-8 flex items-center px-12">
                    <p className="font-owners-text font-bold text-2xl">Email:</p>
                    <p className="ml-12 pt-1 text-center font-owners-text text-xl font-bold">{sessionUser?.email}</p>
                </div>
                <div className="w-screen md:w-[800px] mt-12 border-b-[.5px] border-white py-8 flex items-center px-12">
                    <p className="font-owners-text font-bold text-2xl">Subscription Type:</p>
                    {subscription?.is_active &&
                        <p className="ml-12 pt-1 text-center font-owners-text text-xl font-bold">Unlimited</p>}
                    {!subscription?.is_active &&
                        <p className="ml-12 pt-1 text-center font-owners-text text-xl font-bold">Free Plan</p>}
                </div>
                <div className="py-8">
                    {
                        subscription?.is_active &&
                        <div>
                            <a href={url} target="_blank" rel="noopener noreferrer"
                               className="px-6 py-2 bg-[#334155] text-white rounded hover:bg-blue-900 transition duration-300 my-4">
                                Manage Subscription
                            </a>
                        </div>
                    }
                    {
                        !subscription?.is_active &&
                        <div>
                            <a href={upgradeUrl} target="_blank" rel="noopener noreferrer"
                               className="px-6 py-2 bg-[#334155] text-white rounded hover:bg-blue-900 transition duration-300 my-4">
                                Upgrade To Unlimited
                            </a>
                        </div>
                    }
                </div>
            </div>}
            {showSupportModal &&
                <SupportModal showSupportModal={showSupportModal} setShowSupportModal={setShowSupportModal} />
            }
            {!showMobileMenuOption &&
                <Footer setShowSupportModal={setShowSupportModal}/>
            }
        </div>
    );
}

export default MyAccount;
