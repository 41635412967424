import React, { useEffect, useRef, useState } from 'react';
import 'trix/dist/trix.css';
import 'trix';
import './custom-trix.css'; // Import your custom CSS

const TeksTrixEditor = ({ response, setResponse }) => {
    const editorRef = useRef(null);
    const [content, setContent] = useState('');

    useEffect(() => {
        if (editorRef.current) {
            const element = editorRef.current.editor;
            if (response) {
                element.loadHTML(response);
                setContent(response); // Set initial content in state
            }

            // Add event listener for content changes
            editorRef.current.addEventListener('trix-change', (event) => {
                const updatedContent = event.target.innerHTML;
                setContent(updatedContent); // Update state with new content
                setResponse(updatedContent); //set the response so the text loads on print
            });
        }
    }, [response]);

    return (
        <div>
            <input id="x" type="hidden" name="content" value={content} />
            <trix-editor ref={editorRef} input="x" class="trix-content"></trix-editor>
        </div>
    );
};

export default TeksTrixEditor;
