import React, { useState, useEffect } from 'react';
import {NavLink} from "react-router-dom";
import EmailDashboard from "../Emails";
import {csrfFetch} from "../../store/csrf";

const InspireDashboard = () => {
    const [showEmailDashboard, setShowEmailDashboard] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [inspirer, setInspirer] = useState(null);
    const [password, setPassword] = useState('');
    const [credential, setCredential] = useState(null);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!inspirer) {
            const restoreInspirer = async () => {
                const response = await csrfFetch('/api/session/inspirer');
                const data = await response.json();
                if (!data.inspirer) {
                    console.log("Not Authorized")
                } else {
                    console.log(JSON.stringify(data));
                    setInspirer(data.inspirer);
                    return response;
                }
            };
            restoreInspirer();
        }
    }, [inspirer])

    const login = async(e) => {
        e.preventDefault();
        const response = await csrfFetch('/api/session/inspirer-login', {
            method: 'POST',
            body: JSON.stringify({
                credential,
                password,
            }),
        });
        const data = await response.json();
        if (data.inspirer) {
            window.location.reload();
        }
    }

    return (
        <div className="w-screen">
            {inspirer && <div className="flex justify-between items-center">
                <div className="bg-slate-100 w-screen h-[58px] flex items-center pl-4">
                <p onClick={() => setShowEmailDashboard(false)}
                   className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-[#1ecbe1] hover:text-[#E1341E] cursor-pointer">Inspire</p>
                <p onClick={() => setShowEmailDashboard(true)}
                   className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-[#1ecbe1] hover:text-[#E1341E] cursor-pointer">CRM</p>
                </div>
                <div className="pr-12">
                    <p>Logout</p>
                </div>
            </div>}
            {!showEmailDashboard && inspirer &&
                <div>
                    <p>My Inspire Dashboard</p>
                    <p>Monthly Paid Subscriptions</p>
                    <p>Future Payouts</p>
                    <p>Add a table here</p>
                </div>
            }
            {showEmailDashboard && !showModal && inspirer &&
                <div className="w-screen">
                    <EmailDashboard setShowModal={setShowModal} setModalType={setModalType} />
                </div>
            }
            {showModal && inspirer &&
                <div className="w-full flex justify-center">
                    <div className="absolute bg-black opacity-70 h-screen w-screen z-1"></div>
                    <div className="absolute top-36 rounded-md z-10 bg-white flex flex-col items-center justify-center">
                        { modalType }
                    </div>
                </div>
            }
            {!inspirer &&
                <div>
                    <p>Login To Your Inspire Account:</p>
                    <form onSubmit={(e) => login(e)}
                          className="flex flex-col justify-center items-center sm:w-[450px] mt-8">
                        <ul>
                            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
                        </ul>
                        <label className="w-full">
                            <input
                                type="text"
                                value={credential}
                                onChange={(e) => setCredential(e.target.value)}
                                required
                                className="w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Username or Email"
                            />
                        </label>
                        <label className="w-full">
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-[100%] h-10 border border-grey-100 px-4"
                                placeholder="Password"
                            />
                        </label>
                        <button type="submit"
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2">
                            <i className="fa-solid fa-lock color-gray-300 pt-1 mr-2"></i> Log In
                        </button>
                    </form>
                </div>
            }
        </div>
    )
}

export default InspireDashboard;
