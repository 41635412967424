import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Title } from "../Assets/title.svg"
import {useDispatch, useSelector} from "react-redux";
import * as sessionActions from "../../store/session";
import { ReactComponent as MenuSvg } from "../Assets/menu-svg.svg"

const MainNav = ({ setShowModal, showMobileMenuOption, setShowMobileMenuOption}) => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [loginPopup, setLoginPopup] = useState(null);

    const logout = (e) => {
        e.preventDefault();
        dispatch(sessionActions.logout());
    };

    const navHome = () => {
        window.location.href = '/teks';
    }

    const showLoginPopup = () => {
        setShowModal(true);
    }

    const displayMobileMenuOptions = () => {
        setShowMobileMenuOption(!showMobileMenuOption);
    }

    return (
        <div className="bg-[#1ecbe1] w-screen h-[58px] shadow-xl shadow-[#E1341E] flex items-center">
            {loginPopup}
            <div className="flex pl-2 sm:justify-between md:justify-center items-center">
                <div className="flex">
                    <img src={require("../Assets/ta-logo-svg.png")}  alt="apple image" className="h-[30px] mr-2" />
                    <Title className="mr-12 mt-1 h-[24px] cursor-pointer" onClick={navHome} />
                </div>
                <div className="h-[36px] w-[32px] absolute right-2 top-2 visible md:invisible">
                    <MenuSvg onClick={displayMobileMenuOptions} />
                </div>
                <NavLink exact to="/" className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Free Style Generator</NavLink>
                <NavLink exact to="/teks" className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">TEK Based Generator</NavLink>
                <NavLink exact to="/my-content" className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">My Content</NavLink>
                <NavLink exact to="/search" className="invisible md:visible font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Search</NavLink>
            </div>
            {showMobileMenuOption &&
                <div
                    className="h-screen text-xl bg-black opacity-60 text-white absolute w-full top-14 z-0 overflow-auto flex flex-col items-center pt-24">
                    <NavLink exact to="/" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Free Style Generator</NavLink>
                    <NavLink exact to="/teks" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">TEK Based Generator</NavLink>
                    <NavLink exact to="/my-content" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">My Content</NavLink>
                    <NavLink exact to="/search" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Search</NavLink>
                    {sessionUser ? <NavLink exact to="/my-account" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">My Account</NavLink> : <NavLink exact to="/login" className="font-owners-text tracking-wide block pt-2 px-4 text-white cursor-pointer hover:text-[#E1341E]">Login</NavLink>}
                    {sessionUser ? <a onClick={logout} className="font-owners-text tracking-wide block pt-2 px-4 text-white cursor-pointer hover:text-[#E1341E]">Log Out</a> : <NavLink exact to="/join-now" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Sign Up</NavLink>}
                </div>
            }
            <div className="flex ml-auto pr-8">
                {sessionUser ? <NavLink exact to="/my-account" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">My Account</NavLink> : <p className="font-owners-text tracking-wide block pt-2 px-4 text-white cursor-pointer hover:text-[#E1341E]" onClick={() => showLoginPopup()}>Login</p>}
                {sessionUser ? <a onClick={logout} className="font-owners-text tracking-wide block pt-2 px-4 text-white cursor-pointer hover:text-[#E1341E]">Log Out</a> : <NavLink exact to="/join-now" className="font-owners-text tracking-wide block pt-2 px-4 text-white hover:text-[#E1341E]">Sign Up</NavLink>}
            </div>
        </div>
    )
}

export default MainNav;
