import React from 'react';

const TestPayment = () => {
  const url = 'https://buy.stripe.com/test_eVaaGaaYB7fYg48eUY';

  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        Go to Example.com
      </a>
    </div>
  );
};

export default TestPayment;