import React, {useState} from 'react';
import { labEquipment, labChemicals } from "./lab-supplies";

const LabSuppliesForm = ({ setSelectedEquipment, setSelectedChemicals }) => {
    const [showEquipmentList, setShowEquipmentList] = useState(false);
    const [showChemicalList, setShowChemicalList] = useState(false);
    const [selectedEquipmentList, setSelectedEquipmentList] = useState([]);
    const [selectedChemicalList, setSelectedChemicalList] = useState([]);

    const updateSelectedEquipment = (equipment) => {
        if (selectedEquipmentList.indexOf(equipment) === -1) {
            let currentEquipment = [...selectedEquipmentList];
            currentEquipment.push(equipment);
            setSelectedEquipmentList(currentEquipment);
            setSelectedEquipment(currentEquipment);
        } else {
            let currentEquipment = selectedEquipmentList.filter(type => {
                return type !== equipment;
            })
            setSelectedEquipmentList(currentEquipment);
            setSelectedEquipment(currentEquipment)
        }
    }

    const updateSelectedChemicals = (chemical) => {
        if (selectedChemicalList.indexOf(chemical) === -1) {
            let currentChemical = [...selectedChemicalList];
            currentChemical.push(chemical);
            setSelectedChemicalList(currentChemical);
            setSelectedChemicals(currentChemical)
        } else {
            let currentChemical = selectedChemicalList.filter(type => {
                return type !== chemical;
            })
            setSelectedChemicalList(currentChemical);
            setSelectedChemicals(currentChemical)
        }
    }

    return (
        <div className="flex flex-col items-center px-6 mt-2 mb-2">
            <p className="mt-8 text-lg bg-red-50 px-4 rounded" onClick={() => setShowEquipmentList(!showEquipmentList)} >{showEquipmentList ? "Hide Equipment" : "Select Equipment"}</p>
            {showEquipmentList &&
            <div className="grid grid-cols-4 mt-6">
                {labEquipment.map((equipment, index) => {
                    return <div>
                    <input key={index} value={equipment} type="checkbox" onChange={(e) => updateSelectedEquipment(e.target.value)} />
                      <label className="ml-1">{equipment}</label>
                    </div>
                })}
            </div>}
            <p className="mt-8 text-lg bg-red-50 px-4 rounded" onClick={() => setShowChemicalList(!showChemicalList)} >{showChemicalList ? "Hide Chemicals" : "Select Chemicals"}</p>
            {showChemicalList &&
                <div className="grid grid-cols-4 my-4">
                    {labChemicals.map((chemicals, index) => {
                        return <div>
                            <input key={index} value={chemicals} type="checkbox" onChange={(e) => updateSelectedChemicals(e.target.value)} />
                            <label className="ml-1">{chemicals}</label>
                        </div>
                    })}
                </div>}

            {selectedEquipmentList}
            {selectedChemicalList}
        </div>
    )
}

export default LabSuppliesForm;
