import React, {useEffect, useState} from 'react';
import MainNav from "../MainNav";
import { ReactComponent as BackgroundImage } from "../Assets/background-home.svg"
import {useDispatch, useSelector} from "react-redux";
import * as sessionActions from "../../store/session";
import {csrfFetch} from "../../store/csrf";
import ContentEditor from "./ContentEditor";
import PricingDisplay from "../PricingDisplay";

const MyContent = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state) => state.session.user);
    const [userId, setUserId] = useState(null);
    const [showContent, setShowContent] = useState(false);
    const [userContent, setUserContent] = useState(null);
    const [showContentEditor, setShowContentEditor] = useState(false);
    const [contentEditor, setContentEditor] = useState(null);
    const [contentType, setContentType] = useState(null);
    const [content, setContent] = useState(null);
    const sessionSubscription = useSelector(state => state.session.usersubscription)
    const [showModal, setShowModal] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const [showMobileMenuOption, setShowMobileMenuOption] = useState(false);

    useEffect(() => {
        async function setSession() {
            await setUserAndSubscription();
            await fetchUserContent();
        }
        setSession()
    }, [sessionUser])

    async function fetchUserContent() {
        if (sessionUser) {
            const response = await csrfFetch("/api/teks/fetch-user-content", {
                method: "POST",
                body: JSON.stringify({
                    userId: sessionUser.id
                })
            });
            const data = await response.json();
            if (data) {
                setShowContent(true);
                setUserContent(data);
            }
            return
        }
    }

    async function setUserAndSubscription() {
        try {
            setUserId(sessionUser?.id);
            dispatch(sessionActions.getSubscription(sessionUser.id));
            if (sessionSubscription?.is_active) {
                await fetchUserContent()
            }
        } catch(error) {
            console.log(error);
        }
    }

    const displayContentEditor = (contentId, content, contentType) => {
        setContent(content);
        setContentEditor(<ContentEditor contentId={contentId} content={content} setContent={setContent} contentType={contentType} setContentType={setContentType} closeEditor={closeEditor} />)
    }

    const closeEditor = () => {
        setContentEditor(null);
    }

    return (
        <div>
            {showContent &&
                <MainNav setShowModal={setShowModal} showMobileMenuOption={showMobileMenuOption} setShowMobileMenuOption={setShowMobileMenuOption} />
            }
            {!showSupportModal && !showMobileMenuOption && <BackgroundImage className="absolute w-screen z-0"/>}
            {!showSupportModal && !showMobileMenuOption && <div className="flex flex-col justify-center items-center z-20">
                <div className="z-20">
                    {!showContent &&
                        <div>
                            <PricingDisplay heading={"Unlock Classroom Success With T.A. Unlimited"}
                                            text={"Sign Up For TeachersAIde Unlimited plan to save and edit your created content"}/>
                        </div>
                    }

                    {contentEditor}

                    {showContent &&
                        <div className="mt-8 flex-col flex md:flex-col items-center w-[94%]">
                            <p className="px-4 w-screen md:w-auto py-4">My Created Content</p>
                            <div className="h-[400px] overflow-scroll w-[90%] px-8 py-4 border-[.5px] rounded-2xl">
                                <p>TEKS:</p>
                                <table>
                                    <tr className="font-owners-text text-xs md:text-sm tracking-wide border-b-[.5px] border-slate-50 h-12">
                                        <th>Type</th>
                                        <th>Subject</th>
                                        <th>Grade</th>
                                        <th>Summary</th>
                                        <th>TEKS</th>
                                        <th>Created At</th>
                                    </tr>
                                    {userContent?.userTekLessonplans?.map((userTekLessonplan, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border- h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userTekLessonplan?.user_tek_lessonplan_id, userTekLessonplan?.content, 'user-tek-lessonplan')
                                            }}>
                                            <td>Lessonplan</td>
                                            <td>{userTekLessonplan?.subject.slice(0, 16)}</td>
                                            <td>{userTekLessonplan?.grade.slice(0, 16)}</td>
                                            <td>{userTekLessonplan?.content.slice(0, 30) + '...'}</td>
                                            <td>{userTekLessonplan?.tek.slice(0, 30) + '...'}</td>
                                            <td>{userTekLessonplan?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userTekTests?.map((userTekTest, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-slate-50 h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userTekTest?.user_tek_test_id, userTekTest?.content, 'user-tek-test')
                                            }}>
                                            <td>Tests</td>
                                            <td>{userTekTest?.subject.slice(0, 16)}</td>
                                            <td>{userTekTest?.grade.slice(0, 16)}</td>
                                            <td>{userTekTest?.content.slice(0, 30) + '...'}</td>
                                            <td>{userTekTest?.tek.slice(0, 30) + '...'}</td>
                                            <td>{userTekTest?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userTekWorksheets?.map((userTekWorksheet, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-slate-50 h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userTekWorksheet?.user_tek_worksheet_id, userTekWorksheet?.content, 'user-tek-worksheet')
                                            }}>
                                            <td>Worksheets</td>
                                            <td>{userTekWorksheet?.subject.slice(0, 16)}</td>
                                            <td>{userTekWorksheet?.grade.slice(0, 16)}</td>
                                            <td>{userTekWorksheet?.content.slice(0, 30) + '...'}</td>
                                            <td>{userTekWorksheet?.tek.slice(0, 30) + '...'}</td>
                                            <td>{userTekWorksheet?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userTekLabs?.map((userTekLab, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-slate-50 h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userTekLab?.user_tek_lab_id, userTekLab?.content, 'user-tek-lab')
                                            }}>
                                            <td>Lab</td>
                                            <td>{userTekLab?.subject.slice(0, 16)}</td>
                                            <td>{userTekLab?.grade.slice(0, 16)}</td>
                                            <td>{userTekLab?.content.slice(0, 30) + '...'}</td>
                                            <td>{userTekLab?.tek.slice(0, 30) + '...'}</td>
                                            <td>{userTekLab?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            <div className="h-[400px] overflow-scroll px-8 py-4 border-[.5px] rounded-2xl mt-12">
                                <p>Freestyle:</p>
                                <table className="justify-between">
                                    <tr className="font-owners-text text-xs md:text-sm tracking-wide border-b-[.5px] border-[#E1341E] h-12">
                                        <th className="w-[20%]">Type</th>
                                        <th className="w-[20%]">Subject</th>
                                        <th className="w-[20%]">Grade</th>
                                        <th className="w-[20%]">Summary</th>
                                        <th className="w-[20%]">Created At</th>
                                    </tr>
                                    {userContent?.userFreestyleLessonplans?.map((userFreestyleLessonplan, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-[#E1341E] h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userFreestyleLessonplan?.user_freestyle_lessonplan_id, userFreestyleLessonplan?.content, 'user-freestyle-lessonplan')
                                            }}>
                                            <td>Lessonplan</td>
                                            <td>{userFreestyleLessonplan?.subject.slice(0, 16)}</td>
                                            <td>{userFreestyleLessonplan?.grade.slice(0, 16)}</td>
                                            <td>{userFreestyleLessonplan?.content.slice(0, 30) + '...'}</td>
                                            <td>{userFreestyleLessonplan?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userFreestyleTests?.map((userFreestyleTest, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-[#E1341E] h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userFreestyleTest?.user_freestyle_test_id, userFreestyleTest?.content, 'user-freestyle-test')
                                            }}>
                                            <td>Test</td>
                                            <td>{userFreestyleTest?.subject}</td>
                                            <td>{userFreestyleTest?.grade}</td>
                                            <td>{userFreestyleTest?.content.slice(0, 30) + '...'}</td>
                                            <td>{userFreestyleTest?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userFreestyleWorksheets?.map((userFreestyleWorksheet, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-[#E1341E] h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userFreestyleWorksheet?.user_freestyle_worksheet_id, userFreestyleWorksheet?.content, 'user-freestyle-worksheet')
                                            }}>
                                            <td>Worksheet</td>
                                            <td>{userFreestyleWorksheet?.subject}</td>
                                            <td>{userFreestyleWorksheet?.grade}</td>
                                            <td>{userFreestyleWorksheet?.content.slice(0, 30) + '...'}</td>
                                            <td>{userFreestyleWorksheet?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                    {userContent?.userFreestyleLabs?.map((userFreestyleLab, index) => (
                                        <tr className="font-owners-text text-xs md:text-sm tracking-wide text-center border-b-[.5px] border-[#E1341E] h-12 cursor-pointer hover:text-slate-300"
                                            onClick={() => {
                                                displayContentEditor(userFreestyleLab?.user_freestyle_lab_id, userFreestyleLab?.content, 'user-freestyle-lab')
                                            }}>
                                            <td>Lab</td>
                                            <td>{userFreestyleLab?.subject}</td>
                                            <td>{userFreestyleLab?.grade}</td>
                                            <td>{userFreestyleLab?.content.slice(0, 80) + '...'}</td>
                                            <td>{userFreestyleLab?.createdAt.slice(0, 10)}</td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>}
        </div>
    )
}

export default MyContent;
