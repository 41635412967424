import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import AddEmailTemplate from "./AddEmailTemplate";

const EmailTemplates = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [templates, setTemplates] = useState(null);

    useEffect(() => {
        const fetchTemplates = async() => {
            try {
                const response = await csrfFetch('/api/emails/fetch-email-templates');
                const data = await response.json();
                setTemplates(data["emailTemplates"]);
            } catch(error) {
                console.log(error);
            }
        };
        if (!templates) {
            fetchTemplates();
        }
    })

    const addEmailTemplate = (e) => {
        e.preventDefault();
        setShowModal(true)
        setModalType(<AddEmailTemplate setShowModal={setShowModal} setModalType={setModalType} />)
    }

    return(
        <div className="w-full">
            <div className="flex justify-between px-8 pt-4 font-owners-text mt-2">
                <p className="font-bold">Campaigns</p>
                <button onClick={(e) => addEmailTemplate(e)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add Template</button>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
                <table className="font-owners-text w-[90%] ">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                    <th className="w-[25%] px-8">id</th>
                    <th className="w-[25%] px-8">Name</th>
                    <th className="w-[25%] px-8">Subject</th>
                    <th className="w-[25%] px-2">Created</th>
                    </thead>
                    <tbody>
                    {templates?.map((template, index) => (
                        <tr key={index} className="text-center text-sm">
                            <td>{template.ta_email_template_id}</td>
                            <td>{template.name}</td>
                            <td>{template.subject}</td>
                            <td>{template.createdAt.slice(0,10)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default EmailTemplates;
