import React, { useEffect, useState } from 'react';
import { csrfFetch } from "../../../store/csrf";
import AddContact from "../Contacts/AddContact";
import AddContactList from "./AddContactList";

const ContactList = ({ setShowModal= {setShowModal}, setModalType= {setModalType}, contacts = {contacts}, setContacts = {setContacts} }) => {
    const [contactLists, setContactLists] = useState(null);

    useEffect(() => {
        const fetchContacts = async() => {
            const response = await csrfFetch('/api/emails/fetch-contact-lists', {
                method: "GET"
            });
            const data = await response.json();
            setContactLists(data["contactLists"]);
        };

        if (!contactLists) {
            fetchContacts();
        }
    })

    const addNewContactList = (e) => {
        e.preventDefault();
        setModalType(<AddContactList setShowModal={setShowModal} setModalType={setModalType} contacts={contacts} setContacts={setContacts} />)
        setShowModal(true);
    }

    return(
        <div className="w-full">
            <div className="flex justify-between px-8 pt-4 font-owners-text mt-2">
                <p className="font-bold">Contact Lists</p>
                <button onClick={(e) => addNewContactList(e)} className="bg-[#1bcae1] group relative font-owners-text tracking-wide flex w-auto justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 mb-2">Add List</button>
            </div>
            <div className="mt-8 flex flex-col items-center justify-center">
                <table className="font-owners-text w-[90%] ">
                    <thead className="border-b-[.5px] border-gray-400 font-rift-soft">
                      <th className="w-[25%] px-8">id</th>
                      <th className="w-[25%] px-8">Name</th>
                      <th className="w-[25%] px-8">Contacts</th>
                      <th className="w-[25%] px-8">Created</th>
                    </thead>
                    <tbody>
                        {contactLists?.map((list, index) => (
                            <tr key={index} className="text-sm text-center">
                                <td>{list.ta_contact_list_id}</td>
                                <td>{list.contact_list_name}</td>
                                <td>{JSON.parse(list.ta_contact_ids).length}</td>
                                <td>{list.createdAt.slice(0,10)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ContactList;
