import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as sessionActions from "../../store/session";
import RichTextEditor from "./RichText"
import CookiesBanner from './CookiesBanner'
import { useCookies } from 'react-cookie';
import RegisterAfterAttempts from "../RegisterAfterAttempts"
import Dashboard from "../Dashboard";
import SearchBar from "../Search/searchbar.js";
import { loadingMessages } from "../LoadingMessages/index.js"
import ResetButton from "../form-components/reset-button";
import CommonPrompts from "../CommonPrompts/index.js";

import './index.css'

const MAX_USAGE_COUNT = 3;

function LessonPlan(props) {
  const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState([]);
  const [textContent, setTextContent] = useState("")
  const [visible, setVisible] = useState(false);
  const [canShowNewLoadingText, setCanShowNewLoadingText] = useState(true)
  const [formVisible, setFormVisible] = useState(true);
  const [logoVisible, setLogoVisible] = useState(false);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
  const [cookies, setCookie] = useCookies(['usageCount']);
  const [usageCount, setUsageCount] = useState(cookies.usageCount || 0);
  const [showExamples, setShowExample] = useState(true);
  const [userId, setUserId] = useState(1);
  const [idSet, setIdSet] = useState(false);
  const [formResetButton, setFormResetButton] = useState(false);
  const [commonPromptsForLoad, setCommonPromptsForLoad] = useState(false);
  const user = sessionUser;

  if (user && !idSet) {
    setIdSet(true)
    setCookie('usageCount', '', { expires: new Date(0) });
    setUserId(user.id)
  }

  const incrementUsageCount = () => {
    if (!user) {
      setUsageCount(parseInt(usageCount) + 1);
      setCookie('usageCount', parseInt(usageCount) + 1, { path: '/' });
    }
  };

  const resetForm = () => {
    window.location.reload();
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setErrors([]);
    setTextContent(<span className="animate-pulse">{loadingMessages[0]}...</span>);
    setCommonPromptsForLoad(<CommonPrompts topic={subject} />)
    setTimeout(() => {
      if (canShowNewLoadingText) {
        setTextContent(<span className="animate-pulse">{loadingMessages[1]}...</span>);
      }
    }, 5000);
    setTimeout(() => {
      if (canShowNewLoadingText) {
        setTextContent(<span className="animate-pulse">{loadingMessages[2]}...</span>);
      }
    }, 8000);
    setTimeout(() => {
      if (canShowNewLoadingText) {
        setTextContent(<span className="animate-pulse">{loadingMessages[3]}...</span>);
      }
    }, 12000);
    setLogoVisible(true);
    setFormVisible(false)
    incrementUsageCount();
    let plan = await dispatch(sessionActions.createLessonplan({ grade, subject, userId }));
    setCanShowNewLoadingText(false);

    //check if a url with the uuid is returned and set the textContent to be a link to plan
    const regex = /^https:\/\/www\.teachersaide\.io\/submitted-prompts\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})$/;
    if (regex.test(plan)) {
      try {
       setTimeout(() => {
        setTextContent(<p>You can click this link to view your plan: <a href={plan} target="_blank" rel="noopener noreferrer" className="text-indigo-700">{plan}</a></p>);
        setFormResetButton(ResetButton);
        setLogoVisible(false);
        setCommonPromptsForLoad(false)
       }, 5000)
      } catch {}
    } else {
      setTextContent(<RichTextEditor text={plan} />);
      setFormResetButton(ResetButton);
      setLogoVisible(false);
      setCommonPromptsForLoad(false)
    }
  };

  const saveLessonplan = async(e) => {
    e.preventDefault();
    setErrors([]);
    const planBody = JSON.stringify(textContent["props"]["text"]);
    if (planBody !== "") {
      dispatch(await sessionActions.addLessonplan({ planBody }))
      setSaveSuccessMessage("Plan Saved Successfully");
      setTimeout(() => {
        setSaveSuccessMessage("");
      }, 2000)
    } else {
      setSaveSuccessMessage("Plan cannot be blank")
    }
  };
  
  if (usageCount >= MAX_USAGE_COUNT) {
    return (
      <div>
        <RegisterAfterAttempts />
      </div>
    );
  } else {
  return (
   <div className="flex h-[4000px] max-h-[3500px] flex-row ">
      <Dashboard />
    <div className="flex flex-col items-center w-screen">
      <SearchBar />
      <img src={require("./apple.png")}  alt="apple image" className="w-25 h-[80px] mt-[44px]" />
      <h2 className="mt-6 text-center text-4xl font-bold tracking-tight text-gray-900">Teacher's AIde</h2>
      <p className="mt-2 text-center text-gray-600">A powerful AI assistant that can generate lessons plans for any subject.</p>
        
        {formVisible && <form onSubmit={handleSubmit} className="flex flex-col items-center mt-8">
          <ul>
            {errors.map((error, idx) => <li key={idx}>{error}</li>)}
          </ul>
          <div className="flex flex-col">
          <label className="w-full">
            Enter a grade level:
            <input
              type="text"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              required
              className="h-10 border border-grey-100 px-4 w-full mb-4"
              placeholder="example: 10th"
            />
          </label>
          <label className="w-full">
            Enter a subject
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
              className="h-10 border border-grey-100 px-4 w-full mb-4"
              placeholder="Science, forms of energy and properties of matter"
            />
          </label>
          </div>
          <button className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" type="submit">Create Plan</button>
        </form>}
        <div className="w-full sm:w-4/5 sm:max-w-2xl px-1 mt-6 flex flex-col items-center">
          {visible && 
            <div className="flex">
              <button className="mx-2 mb-4 group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={resetForm} type="submit">Reset</button>
              <button className="mx-2 mb-4 group relative flex w-full justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2" onClick={saveLessonplan} type="submit">Save</button>
              <p className="mx-6 mb-10 text-s">{saveSuccessMessage}</p>
            </div>
          }
          {formResetButton}
          {textContent}
          {logoVisible && <div class="container mt-[14px]">
		        <span>
              <img src={require("./loadingcirclearrow.png")} alt="Description of SVG" className="spin" />
		        </span>
          </div>}
          {commonPromptsForLoad}
        </div>
        <CookiesBanner />
    </div>
  </div>
  );
}
}

export default LessonPlan;